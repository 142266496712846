import React from "react";
import * as Styled from "../stylesView&Create";
import Alert from "@components/common/Alert";
import { LoginContext } from "@context/loginContext";
import { AiOutlineInfoCircle } from "react-icons/ai";
import Spinner from "@components/common/Spinner";
import Button from "@components/common/Button";
import oportunityService from "@services/Oportunity/OportunityService";
import InputText from "@components/Sales/Inputs/InputText";
import { MdOutlineEditNote } from "react-icons/md";
import InputSelect from "@components/Sales/Inputs/InputSelect";
import { optionTypeRelationship } from "@helpers/optionSelect";
import { OportunityContext } from "@context/oportunityContex";
import { documentMask } from "@helpers/format";
import InputTextEdit from "@components/Sales/Inputs/InputTextEdit";
import { useMessageErrorToast } from "@hook/useMessageErrorToast";
import { useOnlyMessageToast } from "@hook/useMessageToast";
import { IViewRalationshipComponent } from "src/@types/Sales";
import { Box, Text } from "@chakra-ui/react";
import { theme } from "src/styles/theme";
import { FiUsers } from "react-icons/fi";
import { IoIosCloseCircleOutline } from "react-icons/io";
import AttachmentsComponent from "../Attachments/AttachmentsComponent";

const ViewRalationshipComponent = ({
  onBack,
  title,
  idRelacionamentoComprador,
  account,
  isEdit,
  setIsEdit,
  setSuccessEditRelationship,
  compradorPrincipal,
  accountIdSalesforce,
  setAccountIdSalesforce,
}: IViewRalationshipComponent) => {
  const displayMessage = useOnlyMessageToast();
  const displayMessageError = useMessageErrorToast();
  const { signOut } = React.useContext(LoginContext);
  const { faseOp, accessRelationship } = React.useContext(OportunityContext);
  const [data, setData] = React.useState<any>([]);
  const [typeRelationship, setTypeRelationship] = React.useState("");
  const [cpf, setCpf] = React.useState("");
  const [mainBuyer, setMainBuyer] = React.useState("");
  const [require, setRequire] = React.useState(false);

  const [isUnauthorized, setIsUnauthorized] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [loadingTaskCall, setLoadingTaskCall] = React.useState(false);
  const [edit, setEdit] = React.useState(false);

  const handleCancelButton = () => {
    setEdit(false);
    setIsEdit(false);
    setTypeRelationship(
      handleTypeRelationshipReverse(data?.tipoRelacionamento)
    );
    setCpf(data?.cpf);
    setMainBuyer(data?.compradorPrincipal ? "Sim" : "Não");
  };

  const handleTypeRelationship = (typeRelationship: string) => {
    if (typeRelationship === "Compositor de Renda") return 1;
    if (typeRelationship === "Cônjuge") return 2;
    if (typeRelationship === "Representante Legal") return 3;
    if (typeRelationship === "Fiador") return 4;
    return null;
  };
  const handleTypeRelationshipReverse = (typeRelationship: number) => {
    if (typeRelationship === 1) return "Compositor de Renda";
    if (typeRelationship === 2) return "Cônjuge";
    if (typeRelationship === 3) return "Representante Legal";
    if (typeRelationship === 4) return "Fiador";
    return "";
  };

  const handleUpdateRelationship = async () => {
    setLoading(true);
    setSuccessEditRelationship(false);
    try {
      const response = await oportunityService.updateRelationship({
        idRelacionamentoComprador: idRelacionamentoComprador,
        accountIdSalesforce,
        cpf,
        oportunidade: data?.oportunidade,
        tipoRelacionamento: handleTypeRelationship(typeRelationship),
      });

      if (response.status >= 400 && response?.status !== 401) {
        setLoading(false);
        displayMessageError({
          message: "Erro ao atualizar relacionamento com o comprador!",
        });
        console.log("status 400", response);
        return;
      }
      if (response.status === 401) {
        setLoading(false);
        setIsUnauthorized(true);
        return;
      }

      setLoading(false);
      setIsEdit(false);
      setEdit(false);
      displayMessage("Alterações salvas com sucesso!");
      setSuccessEditRelationship(true);
    } catch (error) {
      setLoading(false);
      console.log("errorHandleUpdateRelationship", error);
    }
  };

  React.useEffect(() => {
    const isRequirement = !mainBuyer || !typeRelationship;

    setRequire(isRequirement);
  }, [mainBuyer, typeRelationship]);

  React.useEffect(() => {
    if (isEdit) {
      setEdit(true);
    }
  }, [isEdit]);

  React.useEffect(() => {
    const handleGetRelationshipById = async () => {
      setLoadingTaskCall(true);

      try {
        const response = await oportunityService.getRealtionshipById({
          IdRelacionamentoComprador: idRelacionamentoComprador,
        });

        if (response >= 400 && response !== 401) {
          setLoadingTaskCall(false);
          setData([]);
          console.log("status 400", response);
          return;
        }
        if (response === 401) {
          setLoadingTaskCall(false);
          setIsUnauthorized(true);
          setData([]);
          return;
        }

        setTypeRelationship(
          handleTypeRelationshipReverse(response?.tipoRelacionamento)
        );
        setCpf(response?.cpf);
        setMainBuyer(response?.compradorPrincipal ? "Sim" : "Não");
        setData(response);
        setLoadingTaskCall(false);
      } catch (error) {
        setLoadingTaskCall(false);
        console.log("errorHandleGetTaskCallById", error);
      }
    };

    handleGetRelationshipById();
  }, [idRelacionamentoComprador]);

  return (
    <>
      <Alert
        onOpen={isUnauthorized}
        title="Erro ao carregar os dados!"
        titleButton={"Logar novamente"}
        description={
          "Sua sessão expirou, faça login novamente para ter acesso."
        }
        onClick={() => {
          signOut();
        }}
      />
      {loadingTaskCall ? (
        <Styled.ContainerLoadingView>
          <Spinner />
        </Styled.ContainerLoadingView>
      ) : (
        <Styled.AreaSchedule>
          <Styled.ContainerTitleViewRelationship>
            <Box
              display={"flex"}
              w={"36px"}
              h={"32px"}
              borderRadius={"8px"}
              alignItems={"center"}
              justifyContent={"center"}
              bg={theme.primary}
            >
              <Text>{<FiUsers color="white" />}</Text>
            </Box>

            <span>{title}</span>

            <Box
              display={"flex"}
              w={"36px"}
              h={"32px"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Text>
                {
                  <IoIosCloseCircleOutline
                    fontSize={"26px"}
                    color={theme.error}
                    cursor={"pointer"}
                    onClick={() => onBack()}
                  />
                }
              </Text>
            </Box>
          </Styled.ContainerTitleViewRelationship>

          <Styled.ContainerSchedule>
            <Styled.ContainerHeaderWithButton>
              <Styled.TitleActivity>
                <h1>Detalhes do relacionamento</h1>
              </Styled.TitleActivity>

              <Styled.ContaineButtonsHeader>
                <Button
                  title={
                    <Styled.TitleButtons>
                      <MdOutlineEditNote />
                      EDITAR
                    </Styled.TitleButtons>
                  }
                  disabled={edit || faseOp}
                  onClick={() => setEdit(true)}
                />
              </Styled.ContaineButtonsHeader>
            </Styled.ContainerHeaderWithButton>

            <Styled.TitleSchedule></Styled.TitleSchedule>

            <Styled.ContainerInputsSchedule>
              <InputText
                readOnly
                label="Conta:"
                name="Conta"
                value={account || accessRelationship.nomeConta}
              />

              <InputSelect
                isRequirement
                readOnly={!edit || compradorPrincipal}
                widht="36%"
                title="Tipo de relacionamento:"
                label={typeRelationship || "Selecione uma opção"}
                isSelected={typeRelationship}
                options={optionTypeRelationship}
                selectedOption={typeRelationship}
                onChange={(e) => setTypeRelationship(e.currentTarget.value)}
              />
            </Styled.ContainerInputsSchedule>

            <Styled.ContainerInputsSchedule>
              <InputText
                readOnly
                label="Oportunidade:"
                name="Oportunidade"
                value={data?.oportunidade}
              />

              <InputTextEdit
                readOnly={!edit}
                label="CPF:"
                name="CPF"
                value={cpf}
                onChange={(e) => setCpf(documentMask(e.target.value))}
                maxLength={14}
              />
            </Styled.ContainerInputsSchedule>

            <Styled.ContainerInputsSchedule>
              <Styled.ContainerInputsSolo>
                <InputText
                  isRequirement
                  readOnly
                  label="Comprador principal:"
                  name="Comprador principal"
                  value={mainBuyer}
                />
              </Styled.ContainerInputsSolo>
            </Styled.ContainerInputsSchedule>

            {edit && (
              <>
                <Styled.DividerBoxInput />
                <Styled.ContainerFooterBox>
                  <Styled.ContainerDuoMandatory>
                    <span>(*) Campo de preenchimento obrigatório</span>
                    <span>
                      (<AiOutlineInfoCircle />) O campo apresenta formato ou
                      dado inválido
                    </span>
                  </Styled.ContainerDuoMandatory>
                  <Styled.ContainerButtonFooterEdit>
                    <Button
                      error
                      title="CANCELAR"
                      onClick={handleCancelButton}
                      disabled={!edit || faseOp}
                    />

                    <Button
                      title={loading ? <Spinner /> : "SALVAR"}
                      onClick={handleUpdateRelationship}
                      disabled={loading || !edit || faseOp || (edit && require)}
                    />
                  </Styled.ContainerButtonFooterEdit>
                </Styled.ContainerFooterBox>
              </>
            )}
          </Styled.ContainerSchedule>

          <Styled.ContainerSchedule>
            <AttachmentsComponent
              idRelacionamentoComprador={idRelacionamentoComprador}
            />
          </Styled.ContainerSchedule>
        </Styled.AreaSchedule>
      )}
    </>
  );
};

export default ViewRalationshipComponent;
