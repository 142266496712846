import React from "react";
import { useNavigate } from "react-router-dom";
import {
  ILoginContext,
  IProps,
  SignInProps,
} from "../@types/context/loginContext.structure";
import loginService from "@services/Login/loginService";
import { datadogRum } from "@datadog/browser-rum";

export const LoginContext = React.createContext({} as ILoginContext);

const LoginContextProvider: React.FC<IProps> = ({ children }) => {
  const navigate = useNavigate();
  const [user, setUser] = React.useState<boolean>(false);
  const isAuthenticated = user;
  const [profile, setProfile] = React.useState([]);
  const [regional, setRegional] = React.useState(
    localStorage.getItem("@regional")
  );
  const [company, setCompany] = React.useState(
    localStorage.getItem("@empresa")
  );
  const [errorLogin, setErrorLogin] = React.useState<boolean>(false);
  const [loadingLogin, setLoadingLogin] = React.useState<boolean>(false);
  const [openSidebar, setOpenSidebar] = React.useState<boolean>(true);
  const [errorForgotPassword, setErrorForgotPassword] =
    React.useState<boolean>(false);
  const [hashLogin, setHashLogin] = React.useState<string>("PreLogin");
  const [page, setPage] = React.useState<string>("");
  const [email, setEmail] = React.useState<string>("");
  const [pageHelper, setPageHelper] = React.useState<string>("");
  const [pageSidebar, setPageSidebar] = React.useState<string>("");

  const [isRemakeLogin, setIsRemakeLogin] = React.useState(false);

  async function signIn({ email, password }: SignInProps) {
    setLoadingLogin(true);
    try {
      const response = await loginService.postLogin({
        email,
        senha: password,
      });

      if (response?.status >= 400) {
        console.log("errorLogin", response);
        setErrorLogin(true);
        setLoadingLogin(false);
        return;
      }

      if (response.titulo === "Dado divergente") {
        console.log("Dado divergente", response);
        setErrorLogin(true);
        setLoadingLogin(false);
        return;
      }

      const { access_token } = response;

      if (response.deveRefazerPrimeiroAcesso) {
        setIsRemakeLogin(true);
        setLoadingLogin(false);
        return;
      }

      sessionStorage.setItem("@token", access_token);
      localStorage.setItem("@idCorretor", response?.idCorretorSalesforce);
      localStorage.setItem("@email", email);
      localStorage.setItem("@user", response?.nomeCorretor);
      localStorage.setItem("@regional", response?.regional);
      localStorage.setItem("@regiao", response?.regiao);
      localStorage.setItem("@empresa", response?.empresa);
      localStorage.setItem("@cpf", response?.cpf);
      localStorage.setItem("@dtNasc", response?.dataNascimento);
      localStorage.setItem("@option", "Oportunidades");
      // setPageSidebar("Principal");
      setPageSidebar("Oportunidades");

      datadogRum.setUser({
        id: response?.idCorretorSalesforce,
        name: response?.nomeCorretor,
        email: email,
        regional: response?.regional,
        empresa: response?.empresa,
        regiao: response?.regiao,
        cpf: response?.cpf,
      });

      setRegional(response?.regional);
      setCompany(response?.empresa);
      setEmail(email);
      setUser(true);
      // navigate("/home");
      navigate("/oportunidades");
      setLoadingLogin(false);
      setErrorLogin(false);
      setPage("");
    } catch (error) {
      setLoadingLogin(false);
      console.log("ERRO AO FAZER LOGIN ", error);
    }
  }

  function refreshPage() {
    setTimeout(() => {
      window.location.reload();
      sessionStorage.removeItem("@token");
      localStorage.setItem("@option", "Oportunidades");
      datadogRum.clearUser();
    }, 500);
  }

  function signOut() {
    try {
      refreshPage();
    } catch {
      console.log("erro ao deslogar");
    }
  }

  return (
    <LoginContext.Provider
      value={{
        regional,
        setRegional,
        setCompany,
        company,
        hashLogin,
        setHashLogin,
        user,
        setUser,
        email,
        openSidebar,
        setOpenSidebar,
        isAuthenticated,
        page,
        setPage,
        pageHelper,
        setPageHelper,
        profile,
        setProfile,
        signIn,
        signOut,
        errorLogin,
        setErrorLogin,
        loadingLogin,
        errorForgotPassword,
        setErrorForgotPassword,
        pageSidebar,
        setPageSidebar,
        isRemakeLogin,
      }}
    >
      {children}
    </LoginContext.Provider>
  );
};

export default LoginContextProvider;
