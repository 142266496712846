import React from "react";
import * as Styled from "../stylesView&Create";
import { useFormik } from "formik";
import oportunityService from "@services/Oportunity/OportunityService";
import { OportunityContext } from "@context/oportunityContex";
import AccordionCreditRatingComponent from "../../components/AccordionCreditRatingComponent";
import { BsBank } from "react-icons/bs";
import Spinner from "@components/common/Spinner";
import Button from "@components/common/Button";
import { IValidationCreditRatingSchema } from "src/@types/Oportunity";
import enterprisesServiceInstance from "@services/Enterprises/enterprisesService";
import Alert from "@components/common/Alert";
import { LoginContext } from "@context/loginContext";
import { RxUpdate } from "react-icons/rx";
import { optionBankCreditRating } from "@helpers/optionSelect";
import { useMessageErrorToast } from "@hook/useMessageErrorToast";
import { useMessageToast } from "@hook/useMessageToast";
import { IViewCreditRatingComponent } from "src/@types/Sales";
import Modal from "@components/Sales/Modal";
import UpdateDataClientComponent from "../../components/UpdateDataClientComponent";
import { Box, Stack, Text } from "@chakra-ui/react";
import { theme } from "src/styles/theme";

type ValueFormik = {
  observations: string;
};

const ViewCreditRatingComponent = ({ hash }: IViewCreditRatingComponent) => {
  const displayMessage = useMessageToast();
  const displayMessageError = useMessageErrorToast();

  const regionalUser: any = localStorage.getItem("@regional");
  const { signOut } = React.useContext(LoginContext);
  const {
    nameCreditRating,
    nomeConta,
    accessCreditRating,
    setAccessCreditRating,
    statusCreditRating,
    accessCreateOp,
    cnpjCorresponding,
    setCnpjCorresponding,
    allowSendToAnalisys,
    setSuccessUpdateAddresModal,
    setIsUpdateAdress,
    setIsUpdateAdressIncome,
  } = React.useContext(OportunityContext);
  const [bank, setBank] = React.useState("");
  const [estimatedPurchaseValue, setEstimatedPurchaseValue] =
    React.useState("");
  const [futureSale, setFutureSale] = React.useState("");

  const [nomeEmpreendimento, setNomeEmpreendimento] = React.useState("");
  const [idEnterprises, setIdEnterprises] = React.useState("");
  const [enterprises, setEnterprises] = React.useState("");
  const [selectEnterprises, setSelectEnterprises] = React.useState("");

  const [acActivity, setAcActivity] = React.useState("");
  const [dataCreditRating, setDataCreditRating] = React.useState<any>([]);

  const [editAccordion, setEditAccordion] = React.useState(false);

  const [isUnauthorized, setIsUnauthorized] = React.useState(false);
  const [loadingCreditRating, setLoadingCreditRating] = React.useState(false);
  const [loadingEnterprise, setLoadingEnterprise] = React.useState(false);
  const [dataEnterprises, setDataEnterprises] = React.useState([]);
  const [dataGetCreditRating, setDataGetCreditRating] = React.useState([]);

  const [dataCorresponding, setDataCorresponding] = React.useState([]);
  const [corresponding, setCorresponding] = React.useState("");
  const [selectCorresponding, setSelectCorresponding] = React.useState("");
  const [correspondingId, setCorrespondingId] = React.useState("");
  const [loadingCorresponding, setLoadingCorresponding] = React.useState(false);

  const [loadingUpdate, setLoadingUpdate] = React.useState(false);
  const [successEditCreditRating, setSuccessEditCreditRating] =
    React.useState(false);
  const [showModalUpdateDate, setShowModalUpdateDate] = React.useState(false);
  const [showModalUpdateAdress, setShowModalUpdateAdress] =
    React.useState(false);
  const [oportunity, setOportunity] = React.useState<any>();

  const [dataReasonsDisapproval, setDataReasonsDisapproval] = React.useState(
    []
  );

  const initialValues = {
    account: nomeConta,
    realState: dataCreditRating?.imobiliaria,
    oportunity: dataCreditRating?.opportunityIdSalesforce,
    observations: dataCreditRating?.observacoes,
    status: dataCreditRating?.status,
    statusBankRating: dataCreditRating?.statusAnaliseBanco,
    pendingSAFI: dataCreditRating?.motivoPendenciaSafi,
    bankRate: dataCreditRating?.taxaBanco,
    justification: dataCreditRating?.justificativa,
    amortizationTable: dataCreditRating?.tabelaDeAmortizacao,
    modality: dataCreditRating?.modalidade,
    Sicaq: dataCreditRating?.sicaq,
    appraisalValue: dataCreditRating?.valorDeAvaliacao,
    olderAge: dataCreditRating?.idadeMaisVelho,
    financingValue: dataCreditRating?.valorDoFinanciamento,
    calculatedIncome: dataCreditRating?.rendaApurada,
    subsidyAmount: dataCreditRating?.valorDeSubsidio,
    hasDependents: dataCreditRating?.possuiDependentes,
    fgtsCalculated: dataCreditRating?.fgtsApurado,
    typeIncome: dataCreditRating?.tipoDeRenda,
    approvedInstallmentValue: dataCreditRating?.valorParcelaAprovada,
    financingTerm: dataCreditRating?.prazoFinanciamento,
    firstInstallmentFinanced: dataCreditRating?.primeiraParcelaFinanciamento,
    fid: dataCreditRating?.fid,
    integrationStatus: dataCreditRating?.statusDaIntegracao,
    type: dataCreditRating?.tipo,
    dateFirstSentAnalysis: dataCreditRating?.dataPrimeiroEnvioAnalise,
    dateLastSentAnalysis: dataCreditRating?.dataUltimoEnvioAnalise,
    safiApprovalDate: dataCreditRating?.dataAprovacaoSafi,
  };

  const formik: any = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: async (values: any) => handleUpdateCreditRating(values),
  });

  const handleGetCreditRatingById = async () => {
    setLoadingCreditRating(true);
    try {
      const response = await oportunityService.getCreditRatingById({
        IdAvaliacaoCreditoSalesforce:
          accessCreditRating.idAvaliacaoCreditoSalesforce,
      });

      if (response.status === 401) {
        setLoadingCreditRating(false);
        setDataCreditRating([]);
        return;
      }

      if (response.status >= 400 && response?.status !== 401) {
        setLoadingCreditRating(false);
        setDataCreditRating([]);
        console.log("status 400", response);
        return;
      }
      if (response.status === 401) {
        setLoadingCreditRating(false);
        setIsUnauthorized(true);
        return;
      }

      const newValorEstimadoCompraEVenda = response?.valorEstimadoCompraEVenda;

      const formatMoney = (valor: number) => {
        const newValue = new Intl.NumberFormat("pt-BR", {
          style: "currency",
          currency: "BRL",
        }).format(valor);
        return newValue;
      };

      setDataReasonsDisapproval(response?.motivosReprovacao);

      setCnpjCorresponding(response?.cnpjCorrespondente);
      setCorresponding(
        response?.nomeCorrespondente ? response?.nomeCorrespondente : ""
      );
      setSelectCorresponding(
        response?.nomeCorrespondente ? response?.nomeCorrespondente : ""
      );
      setIdEnterprises(response?.idEmpreendimentoSales);
      setNomeEmpreendimento(response.nomeEmpreendimento);

      setDataGetCreditRating(response);
      setEstimatedPurchaseValue(
        formatMoney(newValorEstimadoCompraEVenda?.toString())
      );
      setBank(response?.banco);
      setFutureSale(response?.vendaFutura ? "Sim" : "Não");
      setAcActivity(response?.acAtiva ? "Sim" : "Não");

      const newResp: IValidationCreditRatingSchema = response;
      setDataCreditRating(newResp);
      setLoadingCreditRating(false);
    } catch (error) {
      setLoadingCreditRating(false);
      console.log("ErrorHandleGetCreditRatingById", error);
    }
  };

  const handleGetEnterprises = async () => {
    setLoadingEnterprise(true);

    try {
      const response = await enterprisesServiceInstance.getEnterprises({
        regional: regionalUser,
      });

      if (response.status >= 400 && response?.status !== 401) {
        setLoadingEnterprise(false);
        setDataEnterprises([]);
        console.log("status 400", response);
        return;
      }
      if (response.status === 401) {
        setLoadingEnterprise(false);
        setIsUnauthorized(true);
        setDataEnterprises([]);
        return;
      }

      const dataArray: any = Object.values(response);

      setDataEnterprises(dataArray);
      setLoadingEnterprise(false);
    } catch (error) {
      setLoadingEnterprise(false);
      console.log("errorHandleGetEnterprises", error);
    }
  };

  const handleGetCorresponding = async () => {
    setLoadingCorresponding(true);

    try {
      const response = await oportunityService.getCorresponding();

      if (response.status >= 400 && response?.status !== 401) {
        setLoadingCorresponding(false);
        setDataCorresponding([]);
        console.log("status 400", response);
        return;
      }
      if (response.status === 401) {
        setLoadingCorresponding(false);
        setIsUnauthorized(true);
        setDataCorresponding([]);
        return;
      }

      const dataArray: any = Object.values(response);

      setDataCorresponding(dataArray);
      setLoadingCorresponding(false);
    } catch (error) {
      setLoadingCorresponding(false);
      console.log("errorHandleGetCorresponding", error);
    }
  };

  const handleUpdateCreditRating = async (value: ValueFormik) => {
    setLoadingUpdate(true);

    try {
      const response = await oportunityService.updateCreditRating({
        idAvaliacaoCreditoSalesforce:
          accessCreditRating.idAvaliacaoCreditoSalesforce,
        idEmpreendimentoSales: idEnterprises,
        acAtiva: acActivity === "Sim" ? true : false,
        banco: bank,
        valorEstimadoCompraEVenda: estimatedPurchaseValue,
        observacoes: value.observations,
        nomeCorrespondente: corresponding || null,
        CNPJCorrespondente: cnpjCorresponding || null,
      });

      if (response.status >= 400 && response?.status !== 401) {
        setLoadingUpdate(false);
        displayMessageError({
          message: "Erro ao atualizar avaliação de crédito!",
        });
        console.log("status 400", response);
        return;
      }
      if (response.status === 401) {
        setLoadingUpdate(false);
        setIsUnauthorized(true);
        return;
      }

      setLoadingUpdate(false);
      setEditAccordion(false);
      setSuccessEditCreditRating(true);

      displayMessage({
        title: "Atividade editada com sucesso!",
        message:
          "Suas alterações foram salvas e você já pode continuar sua venda.",
        titleButton: "ACESSAR AVALIAÇÃO DE CRÉDITO",
        onClick: () => setSuccessEditCreditRating(false),
      });
    } catch (error) {
      setLoadingUpdate(false);
      console.log("errorHandleUpdateCreditRating", error);
    }
  };

  const handleSendToSAFI = async () => {
    setLoadingUpdate(true);

    const sentDocuments = await oportunityService.checkDocuments(
      accessCreateOp.oportunityIdSalesforce
    );
    if (!sentDocuments) {
      setLoadingUpdate(false);
      displayMessageError({
        message: "Cliente ainda não enviou documentos obrigatórios.",
      });
      return;
    }

    const creditRating = await oportunityService.getCreditRatingById({
      IdAvaliacaoCreditoSalesforce:
        accessCreditRating.idAvaliacaoCreditoSalesforce,
    });
    const fid = creditRating.fid ?? 0;

    const codigoBanco =
      bank === "Avaliação Interna"
        ? null
        : optionBankCreditRating.find((o) => o.name === bank)?.code ?? 0;

    const safiResponse = await oportunityService.sendToSAFI({
      codigoBanco: codigoBanco,
      fid: fid,
      idAvaliacaoCreditoSalesforce:
        accessCreditRating.idAvaliacaoCreditoSalesforce,
      idEmpreendimentoSales: idEnterprises,
      opportunityIdSalesforce: oportunity?.opportunityIdSalesforce,
      imobiliaria: oportunity?.imobiliaria,
      valorEstimadoCompraEVenda: formatNumber(estimatedPurchaseValue),
      observacao: creditRating?.observacoes ?? "",
      status: creditRating?.status ?? "Rascunho",
      CNPJCorrespondente: cnpjCorresponding || null,
    });

    if (safiResponse.tipoRetorno !== 0) {
      setLoadingUpdate(false);
      displayMessageError({
        message:
          "Favor preencher os dados necessários para avaliação financeira.",
      });
      return;
    }

    displayMessage({
      title: "Avaliação enviada!",
      message:
        "A análise de crédito foi enviada para o SAFI e você já pode continuar sua venda.",
      titleButton: "CONTINUAR",
      onClick: () => {},
    });

    setIsUpdateAdressIncome(false);
    setIsUpdateAdress(false);

    setLoadingUpdate(false);
    setSuccessUpdateAddresModal(false);
    setShowModalUpdateAdress(false);
    setShowModalUpdateDate(false);
    setAccessCreditRating({ ...accessCreditRating });
  };

  const titleHeaderTable = [
    { id: "#", name: "#" },
    { id: "Critério:", name: "Critério:" },
  ];

  const handleAdjustWidth = (id: string) => {
    if (id === "#") return "6%";

    return undefined;
  };

  React.useEffect(() => {
    if (nomeEmpreendimento !== "") {
      setSelectEnterprises(nomeEmpreendimento);
      setEnterprises(nomeEmpreendimento);
    }
  }, [nomeEmpreendimento]);

  React.useEffect(() => {
    if (hash === "ViewCreditRatingComponent") {
      handleGetCreditRatingById();
    }
  }, [hash, accessCreditRating, successEditCreditRating]);

  React.useEffect(() => {
    const handleGetOportunity = async () => {
      const oportunity = await oportunityService.getOportunityById({
        OportunidadeId: accessCreateOp.oportunidadeId,
      });
      setOportunity(oportunity);
    };

    handleGetOportunity();
  }, [accessCreateOp.oportunidadeId]);

  return (
    <>
      <Alert
        onOpen={isUnauthorized}
        title="Erro ao carregar os dados!"
        titleButton={"Logar novamente"}
        description={
          "Sua sessão expirou, faça login novamente para ter acesso."
        }
        onClick={() => {
          signOut();
        }}
      />

      <Modal
        onOpen={showModalUpdateDate}
        title="Atualizar dados pessoais"
        subtitle="Verifique se os dados pessoais estão corretos e atualizados "
        onClose={() => setShowModalUpdateDate(false)}
      >
        <UpdateDataClientComponent
          onBack={() => setShowModalUpdateDate(false)}
          onSuccess={handleSendToSAFI}
          isRequired="AC"
          loadindSuccess={loadingUpdate}
        />
      </Modal>

      {/* <Modal
        onOpen={showModalUpdateAdress}
        title="Atualizar endereço"
        subtitle="Preencha o endereço do comprador principal e dos compositores de renda"
        onClose={() => setShowModalUpdateAdress(false)}
      >
        <UpdateAdressComponent
          onBack={() => {
            setShowModalUpdateAdress(false);
            setShowModalUpdateDate(true);
          }}
          onSuccess={handleSendToSAFI}
          loadindSuccess={loadingUpdate}
        />
      </Modal> */}

      <Styled.ContainerTitlePage>
        <Styled.ContainerTitle>
          <BsBank />
          <Styled.TitlePage>
            {nameCreditRating || accessCreditRating.nome}
          </Styled.TitlePage>
        </Styled.ContainerTitle>

        <Styled.ContainerUpdateButton>
          <Button
            title={
              <RxUpdate style={{ fontSize: "20px", width: "fit-content" }} />
            }
            onClick={() => setAccessCreditRating({ ...accessCreditRating })}
          />
        </Styled.ContainerUpdateButton>

        <Styled.ContainerSendButton>
          <Button
            title={loadingUpdate ? <Spinner /> : "ENVIAR ANALISE FINANCEIRA"}
            onClick={() => setShowModalUpdateDate(true)}
            disabled={loadingUpdate || editAccordion}
          />
        </Styled.ContainerSendButton>
      </Styled.ContainerTitlePage>

      <Styled.ContainerActivityCreditRating>
        <Styled.ContainerDetail>
          <Styled.ContainerAccordion>
            <AccordionCreditRatingComponent
              status={statusCreditRating}
              formik={formik}
              dataGetCreditRating={dataGetCreditRating}
              edit={editAccordion}
              setEdit={setEditAccordion}
              getEnterprises={handleGetEnterprises}
              dataEnterprises={dataEnterprises}
              setIdEnterprise={setIdEnterprises}
              bank={bank}
              setBank={setBank}
              estimatedPurchaseValue={estimatedPurchaseValue}
              setEstimatedPurchaseValue={setEstimatedPurchaseValue}
              futureSale={futureSale}
              setFutureSale={setFutureSale}
              enterprises={enterprises}
              setEnterprises={setEnterprises}
              selectEnterprises={selectEnterprises}
              setSelectEnterprises={setSelectEnterprises}
              loadingEnterprise={loadingEnterprise}
              acActivity={acActivity}
              setAcActivity={setAcActivity}
              loadingUpdate={loadingUpdate}
              handleUpdateCreditRating={formik.handleSubmit}
              handleSendToSAFI={handleSendToSAFI}
              dataCorresponding={dataCorresponding}
              corresponding={corresponding}
              setCorresponding={setCorresponding}
              selectCorresponding={selectCorresponding}
              setSelectCorresponding={setSelectCorresponding}
              setCorrespondingId={setCorrespondingId}
              setCnpjCorresponding={setCnpjCorresponding}
              handleGetCorresponding={handleGetCorresponding}
              loadingCorresponding={loadingCorresponding}
            />
          </Styled.ContainerAccordion>
        </Styled.ContainerDetail>
      </Styled.ContainerActivityCreditRating>

      <Styled.ContainerActivityCreditRating>
        <Styled.ContainerDetail>
          <Styled.ContainerHeaderReasonsDisapproval>
            <Styled.ContainerTitleReasonsDisapproval>
              <span>Motivos da Reprovação</span>
            </Styled.ContainerTitleReasonsDisapproval>
          </Styled.ContainerHeaderReasonsDisapproval>

          <Styled.ContainerTableReasonsDisapproval>
            <Stack display={"flex"} flexDirection={"column"}>
              <Box display={"flex"}>
                {titleHeaderTable.map(({ id, name }) => (
                  <Box key={id} display={"flex"} w={handleAdjustWidth(id)}>
                    <Text fontSize={"10px"} color={theme.text}>
                      {name}
                    </Text>
                  </Box>
                ))}
              </Box>

              {dataReasonsDisapproval.length === 0 ? (
                <Box
                  display={"flex"}
                  bg={theme.backgroundGray}
                  h={"40px"}
                  borderRadius={"8px"}
                  alignItems={"center"}
                  pl={"8px"}
                >
                  <Text fontSize={"11px"} fontWeight={"bold"}>
                    Nenhum motivo foi encontrado.
                  </Text>
                </Box>
              ) : (
                <>
                  {dataReasonsDisapproval.map((message, index) => (
                    <Box key={index} display={"flex"} gap={"4px"}>
                      <Box display={"flex"} w="6%" alignItems={"center"}>
                        <Box
                          display={"flex"}
                          w={"40px"}
                          h={"40px"}
                          borderRadius={"8px"}
                          bg={theme.error}
                          color={"white"}
                          alignItems={"center"}
                          justifyContent={"center"}
                          textAlign={"center"}
                        >
                          <Text fontSize={"15px"} fontWeight={"bold"}>
                            {index + 1 <= 9 ? `0${index + 1}` : index + 1}
                          </Text>
                        </Box>
                      </Box>

                      <Box
                        display={"flex"}
                        w="75%"
                        alignItems={"center"}
                        justifyContent={"center"}
                      >
                        <Text fontSize={"12px"} fontWeight={"bold"}>
                          {message}
                        </Text>
                      </Box>
                    </Box>
                  ))}
                </>
              )}
            </Stack>
          </Styled.ContainerTableReasonsDisapproval>
        </Styled.ContainerDetail>
      </Styled.ContainerActivityCreditRating>
    </>
  );
};

export default ViewCreditRatingComponent;

const formatNumber = (value: string) => {
  let number = value.replace(/\D/g, "");
  return Number(number) / 100;
};
