import { Box, Center, CloseButton, Input, Stack, Text, useToast } from "@chakra-ui/react"
import React, { useEffect } from "react"
import * as Styled from "./styles"
import { theme } from "src/styles/theme"
import { IoTrashOutline } from "react-icons/io5"
import InputText from "@components/Sales/Inputs/InputText"
import InputDate from "@components/Sales/Inputs/InputDate"
import Button from "@components/common/Button"
import { BiBarcodeReader, BiPlus } from "react-icons/bi"
import { MdOutlinePix, MdOutlineSave } from "react-icons/md"
import InputSelectWithService from "@components/Sales/Inputs/InputSelectWithService"
import quotesService from "@services/Quotes/QuotesService"
import { OportunityContext } from "@context/oportunityContex"
import Spinner from "@components/common/Spinner"
import { FaRegCreditCard } from "react-icons/fa"
import MessageToast from "@components/Sales/MessageToast"
import { format, parseISO } from "date-fns"

type Props = {
  data: any
  discount: string
  successCreateMensalPs: boolean
  setDiscount: React.Dispatch<React.SetStateAction<string>>
  setExistMensalPs: React.Dispatch<React.SetStateAction<boolean>>
  setEmptyPortion: React.Dispatch<React.SetStateAction<boolean>>
  disabledIncome: boolean
  disabledStatusApprove: boolean | undefined
  disableEdit: boolean | undefined
  onSalvarParcelamento: () => void
  onAdicionarParcela: () => void
}

type BoxItem = {
  id: number
  idParcelamentoSalesforce: string
  serie: string
  quantidade: string
  valor: number
  prosoluto: number
  dataPagamento: string
  deletado: boolean
  isDisabled?: boolean
  tipoFluxo: string
}

const titleHeaderTableNoProsoluto = [
  { id: "#", name: "#" },
  { id: "Série", name: "Série" },
  { id: "Quantidade", name: "Quantidade" },
  { id: "Valor", name: "Valor" },
  { id: "Data de pagamento", name: "Data de pagamento" },
  { id: "Percentual", name: "Percentual" },
  { id: "Valor total da condição", name: "Valor total da condição" },
]
const titleHeaderTable = [
  { id: "#", name: "#" },
  { id: "Série", name: "Série" },
  { id: "Quantidade", name: "Quantidade" },
  { id: "Valor", name: "Valor" },
  { id: "Data de pagamento", name: "Data de pagamento" },
  { id: "Percentual", name: "Percentual" },
  { id: "% pro soluto", name: "% pró soluto" },
  { id: "Valor total da condição", name: "Valor total da condição" },
]

const initialBox: BoxItem = {
  id: 1,
  idParcelamentoSalesforce: "",
  serie: "",
  quantidade: "1",
  valor: 0,
  prosoluto: 0,
  dataPagamento: "",
  deletado: false,
  isDisabled: false,
  tipoFluxo: "",
}

const TablePortion = ({
  data,
  // discount,
  // setDiscount,
  successCreateMensalPs,
  setExistMensalPs,
  setEmptyPortion,
  disabledIncome,
  disableEdit,
  onAdicionarParcela,
  onSalvarParcelamento,
  disabledStatusApprove,
}: Props) => {
  const toast = useToast()
  const { accessQuote, faseOp, paymentCondition, isSendToApproval, discount, setDiscount } =
    React.useContext(OportunityContext)
  const [boxes, setBoxes] = React.useState<BoxItem[]>([])
  const [formattedDiscount, setFormattedDiscount] = React.useState(0)
  const [somaPercentuais, setSomaPercentuais] = React.useState(0)
  const [hasChanges, setHasChanges] = React.useState(false)

  const [error, setError] = React.useState(false)
  const [messageError, setMessageError] = React.useState("")

  const [idSerie, setIdSerie] = React.useState("")
  const [loadingSerie, setLoadingSerie] = React.useState(false)
  const [dataSerie, setDataSerie] = React.useState([])
  const [loadingInstallment, setLoadingInstallment] = React.useState(false)
  const [loadingCreateInstallment, setLoadingCreateInstallment] = React.useState(false)
  const [isEscalonado, setIsEscalonado] = React.useState(false)

  const valueNumberFormat = (valor: number | string) => {
    const newValor = valor?.toString().replace("R$", "").replace(".", "").replace(",", ".")
    const valorNumerico = parseFloat(newValor)
    return valorNumerico
  }

  const valueTotalCondition = (quantity: string, value: number) => {
    const total = valueNumberFormat(value) * Number(quantity)

    return formatMoney(total)
  }

  const valorTotalComDesconto = data?.valorTotalUnidade - formattedDiscount

  const calcularPercentualParcela = (quantity: string, valorParcela: number) => {
    if (valorTotalComDesconto <= 0) return "0%"

    const total = valueNumberFormat(valorParcela) * Number(quantity)

    const percentual = (total / valorTotalComDesconto) * 100

    return `${percentual.toFixed(2)}%`
  }

  const handleAddBox = () => {
    const newId = boxes.reduce((maxId, box) => Math.max(box.id, maxId), 0) + 1
    const newBox = { ...initialBox, id: newId, deletado: false }
    setBoxes([...boxes, newBox])
    setHasChanges(true)
    onAdicionarParcela()
  }

  const handleRemoveBox = (id: number, serie: string) => {
    const boxToRemove = boxes.find((box) => box.id === id)

    const isPartiallyEmptyBox =
      boxToRemove &&
      (boxToRemove.serie === "" ||
        boxToRemove.quantidade === "" ||
        boxToRemove.valor === 0 ||
        boxToRemove.dataPagamento === "")

    if (isPartiallyEmptyBox) {
      setBoxes(boxes.filter((box) => box.id !== id))
      setHasChanges(false)
    } else {
      if (serie !== "Mensal PS") {
        setBoxes(boxes.map((box) => (box.id === id ? { ...box, deletado: true } : box)))
      } else {
        setBoxes(boxes.map((box) => (box.serie === "Mensal PS" ? { ...box, deletado: true } : box)))
      }
    }
  }

  const handleInputChange = (id: number, field: keyof BoxItem, value: string | number) => {
    if (field === "quantidade") {
      const numericValue = Number(value)

      if (numericValue > 0 && !isNaN(numericValue)) {
        setBoxes(boxes.map((box) => (box.id === id ? { ...box, [field]: numericValue?.toString() } : box)))
      } else {
        setBoxes(boxes.map((box) => (box.id === id ? { ...box, [field]: "" } : box)))
      }
    } else {
      setBoxes(boxes.map((box) => (box.id === id ? { ...box, [field]: value } : box)))
    }
  }

  const handleChangeMoney = (event: React.ChangeEvent<HTMLInputElement>) => {
    let inputValue = event.target.value

    inputValue = inputValue.replace(/\D/g, "")

    const formattedValue = new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }).format(Number(inputValue) / 100)

    return formattedValue
  }

  const handleChangeMoneyDiscount = (event: React.ChangeEvent<HTMLInputElement>) => {
    let inputValue = event.target.value

    inputValue = inputValue.replace(/\D/g, "")

    const numericValue = Number(inputValue) / 100

    setFormattedDiscount(numericValue)

    const formattedValue = new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }).format(numericValue)

    setDiscount(formattedValue)
  }

  const formatMoney = (valor: number) => {
    const newValue = new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }).format(valor)
    return newValue
  }

  const canAddMoreBoxes = boxes
    .filter((box) => !box.deletado)
    .every((box) => box.serie && box.quantidade && box.valor && box.dataPagamento)

  const handleAdjustWidth = (id: string) => {
    if (id === "#") return "25%"
    if (id === "Ação") return "75%"
    if (id === "Percentual") return "40%"
    return undefined
  }

  const handleValueDiscount = (valor: number, discount: number) => {
    const newTotal = valor - discount

    return formatMoney(newTotal)
  }

  const getDateToDay = () => {
    const today = new Date()
    today.setDate(today.getDate())
    const year = today.getFullYear()
    const month = String(today.getMonth() + 1).padStart(2, "0")
    const day = String(today.getDate()).padStart(2, "0")
    return `${year}-${month}-${day}`
  }

  const handleGetTypeInstallment = async () => {
    setLoadingSerie(true)
    try {
      const response = await quotesService.getTypeInstallment({
        IdCondicaoDePagamentoMatriz: data?.condicaoPagamentoMatrizSelecionada,
      })

      if (response.status >= 400 && response?.status !== 401) {
        setLoadingSerie(false)
        setDataSerie([])
        console.log("status 400", response)
        return
      }
      if (response.status === 401) {
        setLoadingSerie(false)
        setDataSerie([])
        return
      }

      const dataArray: any = Object.values(response)

      setDataSerie(dataArray)
      setLoadingSerie(false)
    } catch (error) {
      setLoadingSerie(false)
      console.log("ErrorHandleGetTypeInstallment", error)
    }
  }

  const handleGetInstallment = async () => {
    setLoadingInstallment(true)
    try {
      const response = await quotesService.getInstallment({
        IdCotacaoSalesforce: accessQuote.idCotacaoSalesforce,
      })

      if (response.status >= 400 && response?.status !== 401) {
        setLoadingInstallment(false)
        console.log("status 400", response)
        return
      }
      if (response.status === 401) {
        setLoadingInstallment(false)
        return
      }

      const formatDate = (date: string) => {
        const [value] = date?.split("T")

        return value
      }

      const newResponse = response ? response : []

      let counter = 1
      const formattedData = newResponse?.map((parcela: any) => ({
        id: counter++,
        idParcelamentoSalesforce: parcela?.idParcelamentoSalesforce,
        serie: parcela?.serie,
        quantidade: parcela?.quantidade?.toString(),
        valor: formatMoney(parcela?.valor),
        dataPagamento: formatDate(parcela?.dataDePagamento),
        prosoluto: parcela?.percentualProSoluto,
        tipoFluxo: parcela?.tipoFluxo,
        deletado: false,
        isDisabled: true,
      }))

      setBoxes(formattedData)
      setLoadingInstallment(false)
    } catch (error) {
      setLoadingInstallment(false)
      console.log("ErrorHandleGetInstallment", error)
    }
  }

  const displayToast = ({ message }: { message: string }) => {
    toast.closeAll()
    toast({
      status: "error",
      isClosable: true,
      duration: 4000,
      render: () => (
        <Box display={"flex"} bg={theme.error} p={4} borderRadius={"8px"}>
          <Box color="white" fontWeight={"bold"}>
            {message}
          </Box>
        </Box>
      ),
      containerStyle: {
        w: "fit-content",
        boxShadow: "dark-lg",
        borderRadius: "8px",
      },
    })
  }

  const displayMessage = ({ message }: { message: string }) => {
    toast.closeAll()
    toast({
      position: "top-right",
      status: undefined,
      duration: 6000,
      render: () => (
        <Styled.ContainerToast>
          <Styled.ContainerHeaderToast>
            <span>Bora Vender</span>
            <CloseButton onClick={() => toast.closeAll()} />
          </Styled.ContainerHeaderToast>

          <Styled.ContainerMessageToast>
            <span>{message}</span>
          </Styled.ContainerMessageToast>
        </Styled.ContainerToast>
      ),
      containerStyle: {
        maxWidth: "350px",
        marginTop: "65px",
        boxShadow: "dark-lg",
        borderRadius: "8px",
        bg: "white",
      },
    })
  }

  const handleCreatePortion = async () => {
    setError(false)
    setLoadingCreateInstallment(true)

    const isDataValida = (data: string): boolean => {
      const dataObjeto = new Date(data)

      const anoAtual = new Date().getFullYear()
      const anoData = dataObjeto.getFullYear()

      return anoData >= anoAtual
    }

    const todasDatasValidas = boxes.every((box) => isDataValida(box.dataPagamento))

    if (!todasDatasValidas) {
      setLoadingCreateInstallment(false)
      displayToast({
        message: "Uma ou mais datas de pagamento são inválidas.",
      })
      return
    }

    try {
      const portions = boxes.map((box) => ({
        idCotacaoSalesforce: data?.idCotacaoSalesforce,
        idParcelamentoSalesforce: box.idParcelamentoSalesforce,
        nome: accessQuote.nomeDaCotacao,
        quantidade: Number(box.quantidade),
        DataDePagamentoFormatada: format(parseISO(box.dataPagamento), "dd/MM/yyyy"),
        serie: box.serie,
        valor: valueNumberFormat(box.valor),
        deletado: box.deletado,
        tipoFluxo: box.tipoFluxo,
        percentualProSoluto: Number(box.prosoluto),
      }))

      const response = await quotesService.updatePortion({
        portions,
      })

      if (response.status >= 400 && response?.status !== 401) {
        setLoadingCreateInstallment(false)
        setError(true)
        setMessageError(response?.data)
        console.log("status 400", response)
        return
      }
      if (response.status === 401) {
        setLoadingCreateInstallment(false)
        return
      }

      onSalvarParcelamento()
      displayMessage({ message: "Parcelamento salvo com sucesso!" })
      handleGetInstallment()
      setLoadingCreateInstallment(false)
    } catch (error) {
      setLoadingCreateInstallment(false)
      console.log("ErrorHandleCreatePortion", error)
    }
  }
  const boxesEmpty = () => {
    const box = boxes.filter((box) => !box.deletado).length > 0

    setEmptyPortion(box)

    return box
  }

  const handleExistMensaPS = () => {
    const existeMensalPs = boxes.some((box) => box.serie === "Mensal PS" && !box.deletado)
    const notDeleted = boxes.some((box) => box.serie === "Mensal PS")
    setExistMensalPs(notDeleted)
    return existeMensalPs
  }

  const isAssociative =
    paymentCondition?.toLowerCase()?.includes("associativ") || paymentCondition?.toLowerCase()?.includes("sfh")

  const sumValoresTotal = () => {
    const somaValores = boxes
      .filter((box) => !box.deletado)
      .reduce((acc, box) => acc + Number(box.quantidade) * valueNumberFormat(box.valor), 0)

    return formatMoney(somaValores)
  }

  React.useEffect(() => {
    const calcularSomaPercentuais = () => {
      const somaPercentuais = boxes
        .filter((box) => !box.deletado)
        .reduce((acc, box) => {
          const total = valueNumberFormat(box.valor) * Number(box.quantidade)
          const percentual = valorTotalComDesconto > 0 ? (total / valorTotalComDesconto) * 100 : 0
          return acc + percentual
        }, 0)

      return somaPercentuais.toFixed(2)
    }
    const somaPercentuais = calcularSomaPercentuais()

    setSomaPercentuais(Number(somaPercentuais))
  }, [boxes, valorTotalComDesconto])

  React.useEffect(() => {
    if (successCreateMensalPs || accessQuote.idCotacaoSalesforce) handleGetInstallment()
  }, [accessQuote.idCotacaoSalesforce, setBoxes, successCreateMensalPs])

  React.useEffect(() => {
    const newHeader = boxes.find((box) => box.tipoFluxo?.toLowerCase()?.includes("escalo"))

    setIsEscalonado(!!newHeader)
  }, [boxes])

  useEffect(() => {
    const tempDiscount = discount.replace(/\D/g, "")

    const numericValue = Number(tempDiscount) / 100

    setFormattedDiscount(numericValue)

    const formattedValue = new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }).format(numericValue)

    setDiscount(formattedValue)
  }, [discount])

  return (
    <Stack display={"flex"} flexDirection={"column"}>
      <MessageToast onOpen={error} message={messageError ? messageError : "Erro ao salvar parcelamento!"} />
      {isEscalonado ? (
        <Box display={"flex"}>
          {titleHeaderTable.map(({ id, name }) => (
            <Box
              key={id}
              display={"flex"}
              justifyContent={"space-between"}
              textAlign={id === "#" || (id === "Ação" && isAssociative) ? "initial" : "center"}
              w={handleAdjustWidth(id)}
            >
              <Text fontSize={"10px"} color={theme.text}>
                {name}
              </Text>
            </Box>
          ))}
        </Box>
      ) : (
        <Box display={"flex"}>
          {titleHeaderTableNoProsoluto.map(({ id, name }) => (
            <Box
              key={id}
              display={"flex"}
              justifyContent={"space-between"}
              textAlign={id === "#" || (id === "Ação" && isAssociative) ? "initial" : "center"}
              w={handleAdjustWidth(id)}
            >
              <Text fontSize={"10px"} color={theme.text}>
                {name}
              </Text>
            </Box>
          ))}
        </Box>
      )}

      <Box display={"flex"}>
        {loadingInstallment ? (
          <Box display={"flex"} flex={1} justifyContent={"center"} alignItems={"center"}>
            <Spinner />
          </Box>
        ) : (
          <>
            {!boxesEmpty() ? (
              <Box
                display={"flex"}
                bg={theme.backgroundGray}
                h={"40px"}
                borderRadius={"8px"}
                alignItems={"center"}
                pl={"8px"}
              >
                <Text fontSize={"11px"} fontWeight={"bold"}>
                  Adicione parcelas para criar uma condição de pagamento.
                </Text>
              </Box>
            ) : (
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                alignItems="center"
                flexDirection={"column"}
                rowGap={4}
              >
                {boxes
                  .filter((box) => !box.deletado)
                  .map((box, index, filteredBoxes) => {
                    const isLastBox = index === filteredBoxes.length - 1

                    return (
                      <Box key={box.id} display={"flex"} gap={"4px"}>
                        <Box display={"flex"} w="25%" alignItems={"center"}>
                          <Box
                            display={"flex"}
                            w={"40px"}
                            h={"40px"}
                            borderRadius={"8px"}
                            bg={theme.primary}
                            color={"white"}
                            alignItems={"center"}
                            justifyContent={"center"}
                            textAlign={"center"}
                          >
                            <Text fontSize={"15px"} fontWeight={"bold"}>
                              {index + 1 <= 9 ? `0${index + 1}` : index + 1}
                            </Text>
                          </Box>
                        </Box>

                        {/* {isAssociative ? (
                          <Box display={"none"} w="75%" alignItems={"center"}>
                            <Box
                              display={"flex"}
                              w="70%"
                              fontSize={"22px"}
                              gap={"6px"}
                            >
                              <BiBarcodeReader
                                color={theme.primary}
                                cursor={"pointer"}
                              />
                              <FaRegCreditCard
                                color={theme.primary}
                                cursor={"pointer"}
                              />
                              <MdOutlinePix
                                color={theme.primary}
                                cursor={"pointer"}
                              />
                            </Box>
                          </Box>
                        ) : (
                          <Box
                            display={"flex"}
                            w="75%"
                            alignItems={"center"}
                            justifyContent={"center"}
                          >
                            <Text
                              fontSize={"11px"}
                              fontWeight={"bold"}
                              textDecoration={"underline"}
                              color={theme.primary}
                              align={"center"}
                              cursor={"pointer"}
                            >
                              Link para download
                            </Text>
                          </Box>
                        )} */}

                        <Box display={"flex"} justifyContent={"center"}>
                          <InputSelectWithService
                            widht="20%"
                            title=""
                            label={box.serie || "Selecione uma opção"}
                            isSelected={box.serie}
                            options={dataSerie}
                            selectedOption={box.serie}
                            onClick={() => handleGetTypeInstallment()}
                            onChange={(e) => handleInputChange(box.id, "serie", e.target.value)}
                            setId={setIdSerie}
                            loading={loadingSerie}
                            disabled={box.isDisabled || !isLastBox || box.serie === "Mensal PS" || isSendToApproval}
                          />
                        </Box>
                        <Box display={"flex"} justifyContent={"center"}>
                          <InputText
                            label=""
                            name="quantity"
                            value={box.quantidade}
                            onChange={(e) => handleInputChange(box.id, "quantidade", e.target.value)}
                            readOnly={box.isDisabled || !isLastBox || box.serie === "Mensal PS" || isSendToApproval}
                          />
                        </Box>
                        <Box display={"flex"} justifyContent={"center"}>
                          <InputText
                            label=""
                            name="value"
                            value={box.valor}
                            onChange={(e) => handleInputChange(box.id, "valor", handleChangeMoney(e))}
                            readOnly={box.isDisabled || !isLastBox || box.serie === "Mensal PS" || isSendToApproval}
                          />
                        </Box>
                        <Box display={"flex"} justifyContent={"center"}>
                          <InputDate
                            min={getDateToDay()}
                            type="date"
                            name={"date"}
                            value={box.dataPagamento}
                            onChange={(e) => {
                              handleInputChange(box.id, "dataPagamento", e.target.value)
                            }}
                            readOnly={box.isDisabled || !isLastBox || box.serie === "Mensal PS" || isSendToApproval}
                          />
                        </Box>

                        <Box
                          display={"flex"}
                          alignItems={"center"}
                          textAlign={"center"}
                          justifyContent={"center"}
                          w="40%"
                        >
                          <Text fontSize={"11px"} fontWeight={"bold"} w={"fit-content"}>
                            {calcularPercentualParcela(box.quantidade, box.valor)}
                          </Text>
                        </Box>

                        {isEscalonado && (
                          <Box display={"flex"} justifyContent={"center"}>
                            <InputText
                              label=""
                              name="prosoluto"
                              value={box.prosoluto}
                              onChange={(e) => handleInputChange(box.id, "prosoluto", e.target.value)}
                              readOnly={(box.serie !== "Mensal PS" && isEscalonado) || isSendToApproval}
                            />
                          </Box>
                        )}

                        <Box display={"flex"} alignItems={"center"} textAlign={"center"}>
                          <Text fontSize={"11px"} fontWeight={"bold"}>
                            {valueTotalCondition(box.quantidade, box.valor)}
                          </Text>

                          <Box
                            display={"flex"}
                            alignItems={"center"}
                            justifyContent={"flex-end"}
                            textAlign={"center"}
                            w={"fit-content"}
                          >
                            <Box
                              display={"flex"}
                              w={"30px"}
                              h={"30px"}
                              borderWidth={"1px"}
                              borderColor={
                                isSendToApproval || disableEdit || disabledStatusApprove ? theme.disable : theme.error
                              }
                              borderRadius={"8px"}
                              alignItems={"center"}
                              justifyContent={"center"}
                              textAlign={"center"}
                              cursor={
                                isSendToApproval || disableEdit || disabledStatusApprove ? "not-allowed" : "pointer"
                              }
                              onClick={() => {
                                if (isSendToApproval || disableEdit || disabledStatusApprove) {
                                } else {
                                  handleRemoveBox(box.id, box.serie)
                                }
                              }}
                            >
                              <IoTrashOutline
                                color={
                                  isSendToApproval || disableEdit || disabledStatusApprove ? theme.disable : theme.error
                                }
                              />
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    )
                  })}
              </Box>
            )}
          </>
        )}
      </Box>

      <Box display={"flex"} w={"full"} h={"1px"} bg={theme.disable} borderRadius={"8px"} mt={"4px"} />

      <Stack display={"flex"} flexDir={"row"} justifyContent={"space-between"}>
        <Stack display={"flex"} flexDir={"row"} alignItems={"center"}>
          <Box display={"flex"} w={"200px"}>
            <Button
              title={
                <Box display={"flex"} alignItems={"center"} justifyContent={"center"}>
                  <Box width={"fit-content"} fontSize={"20px"} alignItems={"center"} justifyContent={"center"}>
                    <BiPlus />
                  </Box>
                  ADICIONAR PARCELA
                </Box>
              }
              onClick={handleAddBox}
              disabled={
                !canAddMoreBoxes ||
                faseOp ||
                handleExistMensaPS() ||
                disableEdit ||
                disabledStatusApprove ||
                (disabledIncome && isAssociative)
              }
            />
          </Box>

          {/* <Box display={"flex"} w={"150px"}>
            <Box
              display={"flex"}
              flexDir={"column"}
              borderWidth={"2px"}
              borderColor={
                valueNumberFormat(discount) > valueNumberFormat(data?.valorTotalUnidade) ? theme.error : theme.disable
              }
              borderRadius={"8px"}
              h={"38px"}
              pl={"6px"}
            >
              <Text fontSize={"10px"}>Desconto</Text>
              <Input
                variant={"unstyled"}
                color={
                  valueNumberFormat(discount) > valueNumberFormat(data?.valorTotalUnidade) ? theme.error : theme.text
                }
                name="discount"
                value={data?.valorTotalComDesconto}
                onChange={(e) => handleChangeMoneyDiscount(e)}
                fontSize={14}
                fontWeight="bold"
                focusBorderColor={"none"}
                readOnly={isSendToApproval}
              />
            </Box>
          </Box> */}
        </Stack>

        <Stack display={"flex"} flexDir={"row"}>
          <Box display={"flex"} w={{ base: "120px", "2xl": "170px" }}>
            <Box display={"flex"} flexDir={"column"}>
              <Box
                fontSize={{ base: "10px", "2xl": "14px" }}
                color={theme.text}
                display={"flex"}
                flexDir={"column"}
                gap={{ base: "", "2xl": "2px" }}
              >
                <Text>Valor da unidade: </Text>
                <Text fontWeight={"bold"} w={"fit-content"}>
                  {data?.valorTotalUnidade ? formatMoney(data?.valorTotalUnidade) : "R$ 0,00"}
                </Text>
              </Box>
              <Box
                fontSize={{ base: "10px", "2xl": "14px" }}
                color={theme.text}
                display={"flex"}
                flexDir={"column"}
                gap={{ base: "", "2xl": "2px" }}
              >
                <Text>Valor final com desconto: </Text>
                <Text fontWeight={"bold"} w={"fit-content"} color={theme.primary}>
                  {data?.valorTotalUnidade
                    ? handleValueDiscount(data?.valorTotalUnidade, formattedDiscount)
                    : "R$ 0,00"}
                </Text>
              </Box>
            </Box>
          </Box>

          <Box display={"flex"} w={"1px"} h={"full"} bg={theme.disable} borderRadius={"8px"} />

          <Box display={"flex"} w={{ base: "120px", "2xl": "140px" }}>
            <Box display={"flex"} flexDir={"column"}>
              <Text
                fontSize={{ base: "10px", "2xl": "14px" }}
                color={theme.text}
                display={"flex"}
                gap={{ base: "", "2xl": "4px" }}
              >
                Soma do percentual:
              </Text>
              <Text fontSize={{ base: "12px", "2xl": "14px" }} fontWeight={"bold"} w={"fit-content"}>
                {somaPercentuais.toFixed(2)}%
              </Text>
            </Box>
          </Box>

          <Box display={"flex"} w={"1px"} h={"full"} bg={theme.disable} borderRadius={"8px"} />

          <Box display={"flex"} w={{ base: "120px", "2xl": "230px" }}>
            <Box display={"flex"} flexDir={"column"}>
              <Text
                fontSize={{ base: "10px", "2xl": "14px" }}
                color={theme.text}
                display={"flex"}
                gap={{ base: "", "2xl": "4px" }}
              >
                Soma do valor total da condição:
              </Text>
              <Text fontSize={{ base: "12px", "2xl": "14px" }} fontWeight={"bold"} w={"fit-content"}>
                {sumValoresTotal()}
              </Text>
            </Box>
          </Box>

          <Box display={"flex"} w={"220px"}>
            <Button
              title={
                <Box display={"flex"} alignItems={"center"} justifyContent={"center"} gap={"4px"}>
                  {loadingCreateInstallment ? (
                    <Center>
                      <Spinner />
                    </Center>
                  ) : (
                    <>
                      <Box width={"fit-content"} fontSize={"20px"} alignItems={"center"} justifyContent={"center"}>
                        <MdOutlineSave />
                      </Box>
                      SALVAR PARCELAMENTO
                    </>
                  )}
                </Box>
              }
              onClick={handleCreatePortion}
              disabled={
                isSendToApproval ||
                faseOp ||
                !canAddMoreBoxes ||
                boxes.length === 0 ||
                loadingCreateInstallment ||
                valueNumberFormat(discount) > valueNumberFormat(data?.valorTotalUnidade) ||
                disableEdit ||
                disabledStatusApprove
              }
            />
          </Box>
        </Stack>
      </Stack>
    </Stack>
  )
}

export default TablePortion
