import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
} from "@chakra-ui/react";
import React from "react";
import { theme } from "src/styles/theme";
import * as Styled from "../Steps/stylesStepTab";
import InputText from "@components/Sales/Inputs/InputText";
import InputSelect from "@components/Sales/Inputs/InputSelect";
import {
  optionAgeRange,
  optionCivilStatus,
  optionMarriageRegime,
  optionNationality,
  optionOriginAccount,
  optionSex,
  optionTypeSale,
} from "@helpers/optionSelect";
import InputSelectSearch from "@components/Sales/Inputs/InputSelectSearch";
import {
  documentMask,
  formatDatePtBr,
  phoneMask,
  rgMask,
} from "@helpers/format";
import InputTextEdit from "@components/Sales/Inputs/InputTextEdit";
import { IAccordionComponent } from "src/@types/Sales";
import { optionProfession } from "./options";
import Tab from "@components/Sales/Tab";
import MainBuyerComponent from "../Steps/Adress/MainBuyerComponent";
import IncomeComposersComponent from "../Steps/Adress/IncomeComposersComponent";
import InputTextArea from "@components/Sales/Inputs/InputTextArea";

const AccordionComponent = ({
  formik,
  edit,
  setEdit,
  originAccount,
  setOriginAccount,
  nacionality,
  setNacionality,
  ageRange,
  setAgeRange,
  sex,
  setSex,
  profession,
  setProfession,
  civilState,
  setCivilState,
  marriageRegime,
  setMarriageRegime,
  typeSale,
  setTypeSale,
  mainCampaign,
  setMainCampaign,
  selectMainCampaign,
  setSelectMainCampaign,
  loadingCampaign,
  fallReason,
  setFallReason,
  selectMainEnterprises,
  setSelectMainEnterprises,
  mainEnterprises,
  setMainEnterprises,
  loadingMainEnterprise,
  handleGetEnterprises,
  getEnterprises,
  handleGetCampaign,
  getCampaign,
  loadingProfession,
  selectProfession,
  setSelectProfession,
  dataProfession,
  getProfession,
  setIdProfession,
  relationship,
  setLoadingUpdateAdress,
  observationsGa,
  cancelButtonAdress,
}: IAccordionComponent) => {
  const nameUser: any = localStorage.getItem("@user");
  const [index, setIndex] = React.useState(0);

  const handleRequireOriginAccount = (originAccount: string) => {
    return (
      originAccount !== "Oferta do corretor" &&
      originAccount !== "Leads autorais"
    );
  };

  const renderedOptionsProfession = optionProfession(
    dataProfession,
    selectProfession,
    setSelectProfession,
    setProfession,
    setIdProfession
  );

  const tabLabels = ["Comprador principal", "Compositores de renda"];

  const tabContents = {
    "Comprador principal": (
      <MainBuyerComponent
        edit={edit}
        relationship={relationship}
        setLoadingUpdateAdress={setLoadingUpdateAdress}
        isCancel={cancelButtonAdress}
        setIsClicked={() => {}}
        onSuccess={() => {}}
      />
    ),
    "Compositores de renda": (
      <IncomeComposersComponent
        edit={edit}
        relationship={relationship}
        setLoadingUpdateAdress={setLoadingUpdateAdress}
        isCancel={cancelButtonAdress}
        setIsClicked={() => {}}
        onSuccess={() => {}}
      />
    ),
  };

  return (
    <Accordion allowMultiple>
      <AccordionItem mt={"10px"} borderRadius={8}>
        <AccordionButton bg={theme.disable100} borderRadius={8}>
          <Box
            as="span"
            flex="1"
            textAlign="left"
            fontWeight={"bold"}
            fontSize={"18px"}
          >
            Detalhes da oportunidade
          </Box>
          <AccordionIcon />
        </AccordionButton>

        <AccordionPanel pb={4}>
          <Styled.ContainerBoxInputs>
            <InputSelect
              isRequirement
              readOnly={!edit || handleRequireOriginAccount(originAccount)}
              title="Origem da conta:"
              label={originAccount || "Selecione uma opção"}
              isSelected={originAccount}
              options={optionOriginAccount}
              selectedOption={originAccount}
              onChange={(e) => setOriginAccount(e.currentTarget.value)}
            />

            <InputText
              isRequirement
              readOnly
              label="Nome da conta:"
              name="nameAccount"
              value={formik.values.nameAccount?.toUpperCase()}
            />

            <InputText
              isRequirement
              readOnly
              label="Telefone principal:"
              name="mainTelephone"
              value={formik.values.mainTelephone}
            />

            <InputTextEdit
              readOnly={!edit}
              label="Telefone:"
              name="telephone"
              value={formik.values.telephone}
              onChange={(e) => {
                const newValue = phoneMask(e.target.value);
                formik.handleChange(e);
                formik.setFieldValue("telephone", newValue);
              }}
              maxLength={14}
            />

            <InputTextEdit
              isRequirement
              readOnly={!edit}
              label="E-mail principal:"
              name="mainEmail"
              value={formik.values.mainEmail}
              onChange={formik.handleChange}
            />

            <InputTextEdit
              readOnly={!edit}
              label="E-mail:"
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
            />

            <InputTextEdit
              readOnly={!edit}
              name={"dateBirth"}
              label="Data de nascimento:"
              placeholder="__/__/___"
              value={formik.values.dateBirth}
              onChange={(e) => {
                const newValue = formatDatePtBr(e.target.value);
                formik.handleChange(e);
                formik.setFieldValue("dateBirth", newValue);
              }}
              maxLength={10}
            />

            <InputSelect
              readOnly={!edit}
              title="Nacionalidade:"
              label={nacionality || "Selecione uma opção"}
              isSelected={nacionality}
              options={optionNationality}
              selectedOption={nacionality}
              onChange={(e) => setNacionality(e.currentTarget.value)}
            />

            <InputSelect
              readOnly={!edit}
              title="Faixa Etária:"
              label={ageRange || "Selecione uma opção"}
              isSelected={ageRange}
              options={optionAgeRange}
              selectedOption={ageRange}
              onChange={(e) => setAgeRange(e.currentTarget.value)}
            />

            <InputSelect
              readOnly={!edit}
              title="Sexo:"
              label={sex || "Selecione uma opção"}
              isSelected={sex}
              options={optionSex}
              selectedOption={sex}
              onChange={(e) => setSex(e.currentTarget.value)}
            />

            <InputTextEdit
              readOnly={!edit}
              label="CPF/CNPJ:"
              name="cpf"
              value={formik.values.cpf}
              onChange={(e) => {
                const newValue = documentMask(e.target.value);
                formik.handleChange(e);
                formik.setFieldValue("cpf", newValue);
              }}
              maxLength={18}
              tooltip
              tooltipText="Campo necessário para avaliação financeira"
            />

            <InputTextEdit
              readOnly={!edit}
              label="RG:"
              name="rg"
              value={formik.values.rg}
              onChange={(e) => {
                const newValue = rgMask(e.target.value);
                formik.handleChange(e);
                formik.setFieldValue("rg", newValue);
              }}
              maxLength={18}
              tooltip
              tooltipText="Campo necessário para avaliação financeira"
            />

            <InputTextEdit
              readOnly={!edit}
              name={"shippingDate"}
              label="Data de expedição:"
              placeholder="__/__/___"
              value={formik.values.shippingDate}
              onChange={(e) => {
                const newValue = formatDatePtBr(e.target.value);
                formik.handleChange(e);
                formik.setFieldValue("shippingDate", newValue);
              }}
              maxLength={10}
            />

            <InputTextEdit
              readOnly={!edit}
              label="Órgão expedidor:"
              name="issuingBody"
              value={formik.values.issuingBody}
              onChange={formik.handleChange}
            />

            <InputSelectSearch
              disabled={!edit}
              width="22%"
              height="15%"
              title="Profissão:"
              placeholder="Pesquise por profissão"
              value={selectProfession}
              onChangeInput={(e) => setSelectProfession(e.target.value)}
              isSelected={profession}
              onChangeIcon={() => {
                setSelectProfession("");
                setProfession("");
              }}
              onClickSearchIcon={getProfession}
              loading={loadingProfession}
            >
              {renderedOptionsProfession}
            </InputSelectSearch>

            <InputSelect
              readOnly={!edit}
              title="Estado civil:"
              label={civilState || "Selecione uma opção"}
              isSelected={civilState}
              options={optionCivilStatus}
              selectedOption={civilState}
              onChange={(e) => setCivilState(e.currentTarget.value)}
              tooltip="Campo necessário para avaliação financeira"
            />

            {civilState === "Casado(a)" && (
              <>
                <InputTextEdit
                  readOnly={!edit}
                  name={"weddingDate"}
                  label="Data casamento:"
                  placeholder="__/__/___"
                  value={formik.values.weddingDate}
                  onChange={(e) => {
                    const newValue = formatDatePtBr(e.target.value);
                    formik.handleChange(e);
                    formik.setFieldValue("weddingDate", newValue);
                  }}
                  maxLength={10}
                  isRequirement
                />

                <InputSelect
                  readOnly={!edit}
                  title="Regime casamento:"
                  label={marriageRegime || "Selecione uma opção"}
                  isSelected={marriageRegime}
                  options={optionMarriageRegime}
                  selectedOption={marriageRegime}
                  onChange={(e) => setMarriageRegime(e.currentTarget.value)}
                  isRequirement
                />
              </>
            )}
          </Styled.ContainerBoxInputs>
        </AccordionPanel>
      </AccordionItem>

      <AccordionItem mt={"10px"} borderRadius={8}>
        <AccordionButton bg={theme.disable100} borderRadius={8}>
          <Box
            as="span"
            flex="1"
            textAlign="left"
            fontWeight={"bold"}
            fontSize={"18px"}
          >
            Endereço
          </Box>
          <AccordionIcon />
        </AccordionButton>

        <AccordionPanel pb={4}>
          <Styled.ContainerActivitySubTab>
            <Tab
              widthXl="35%"
              width="60%"
              widthMd="55%"
              widthSm="100%"
              widthBase="100%"
              fontSize="13px"
              setIndex={setIndex}
              tabLabels={tabLabels}
              tabContents={tabContents}
            />
          </Styled.ContainerActivitySubTab>
        </AccordionPanel>
      </AccordionItem>

      <AccordionItem mt={"10px"} borderRadius={8}>
        <AccordionButton bg={theme.disable100} borderRadius={8}>
          <Box
            as="span"
            flex="1"
            textAlign="left"
            fontWeight={"bold"}
            fontSize={"18px"}
          >
            Produto de interesse
          </Box>
          <AccordionIcon />
        </AccordionButton>

        <AccordionPanel pb={4}>
          <Styled.ContainerBoxInputsSearch>
            <InputSelectSearch
              disabled={!edit}
              width="30%"
              height="15%"
              title="Emp. de interesse principal:"
              placeholder="Pesquise por empreendimento"
              value={selectMainEnterprises}
              onChangeInput={(e) => setSelectMainEnterprises(e.target.value)}
              isSelected={mainEnterprises}
              onChangeIcon={() => {
                setMainEnterprises("");
                setSelectMainEnterprises("");
              }}
              onClickSearchIcon={handleGetEnterprises}
              loading={loadingMainEnterprise}
            >
              {getEnterprises}
            </InputSelectSearch>

            <InputSelectSearch
              disabled={!edit}
              width="30%"
              height="15%"
              title="Origem da campanha principal:"
              placeholder="Pesquise por campanha"
              value={selectMainCampaign}
              onChangeInput={(e) => setSelectMainCampaign(e.target.value)}
              isSelected={mainCampaign}
              onChangeIcon={() => {
                setMainCampaign("");
                setSelectMainCampaign("");
              }}
              onClickSearchIcon={handleGetCampaign}
              loading={loadingCampaign}
            >
              {getCampaign}
            </InputSelectSearch>
          </Styled.ContainerBoxInputsSearch>
        </AccordionPanel>
      </AccordionItem>

      <AccordionItem mt={"10px"} borderRadius={8}>
        <AccordionButton bg={theme.disable100} borderRadius={8}>
          <Box
            as="span"
            flex="1"
            textAlign="left"
            fontWeight={"bold"}
            fontSize={"18px"}
          >
            Informações da oportunidade
          </Box>
          <AccordionIcon />
        </AccordionButton>

        <AccordionPanel pb={4}>
          <Styled.ContainerBoxInputs>
            <InputText
              isRequirement
              readOnly
              name={"idOportunity"}
              label="ID da oportunidade:"
              placeholder="ID da oportunidade"
              value={formik.values.idOportunity}
            />
            <InputText
              readOnly
              name={"dateSale"}
              label="Data da venda:"
              placeholder="__/__/___"
              value={formatDatePtBr(formik.values.dateSale)}
            />
            <InputText
              readOnly
              name={"dateClosing"}
              label="Data de fechamento:"
              placeholder="__/__/___"
              value={formatDatePtBr(formik.values.dateClosing)}
            />
            <InputText
              isRequirement
              readOnly
              name={"dateOportunity"}
              label="Data da oportunidade:"
              placeholder="__/__/___"
              value={formatDatePtBr(formik.values.dateOportunity)}
            />

            <InputSelect
              readOnly={!edit}
              title="Tipo de venda:"
              label={typeSale || "Selecione uma opção"}
              isSelected={typeSale}
              options={optionTypeSale}
              selectedOption={typeSale}
              onChange={(e) => setTypeSale(e.currentTarget.value)}
            />

            <InputText
              isRequirement
              readOnly
              label="Nome da oportunidade:"
              name="nameOportunity"
              value={formik.values.nameOportunity}
            />

            <InputText
              isRequirement
              readOnly
              label="Imobiliária:"
              name="realEstate"
              value={formik.values.realEstate}
            />

            <InputText
              isRequirement
              readOnly
              label="Contato consultor(a):"
              name="contactConsultant"
              value={nameUser}
            />

            <InputText
              isRequirement
              readOnly
              label="Regional comercial:"
              name="commercialRegion"
              value={formik.values.commercialRegion}
            />

            <InputText
              isRequirement
              readOnly
              label="Empreendimento:"
              name="enterprise"
              value={formik.values.enterprise}
            />

            <InputText
              isRequirement
              readOnly
              label="Produto:"
              name="product"
              value={formik.values.product}
            />

            <InputText
              isRequirement
              readOnly
              label="Unidade de negócio:"
              name="businessUnit"
              value={formik.values.businessUnit}
            />

            <InputText
              isRequirement
              readOnly
              label="Status do contrato:"
              name="statusContract"
              value={formik.values.statusContract}
            />

            <InputText
              isRequirement
              readOnly
              label="Valor:"
              name="value"
              value={formik.values.value}
            />

            <InputText
              isRequirement
              readOnly
              label="Valor contrato:"
              name="valueContract"
              value={formik.values.valueContract}
            />

            <InputText
              isRequirement
              readOnly
              label="Valor real da venda:"
              name="realValueSale"
              value={formik.values.realValueSale}
            />

            <InputText
              isRequirement
              readOnly
              label="Fase:"
              name="fase"
              value={formik.values.fase}
            />

            <InputSelect
              readOnly={!edit}
              title="Motivo da queda:"
              label={fallReason || "Selecione uma opção"}
              isSelected={fallReason}
              options={[]}
              selectedOption={fallReason}
              onChange={(e) => setFallReason(e.currentTarget.value)}
            />
          </Styled.ContainerBoxInputs>

          <Styled.ContainerInputObservation>
            <InputTextArea
              readOnly
              label="Observações GA:"
              name="observationsGA"
              value={observationsGa}
              noMaxLength
            />
          </Styled.ContainerInputObservation>
        </AccordionPanel>
      </AccordionItem>

      <AccordionItem mt={"10px"} borderRadius={8}>
        <AccordionButton bg={theme.disable100} borderRadius={8}>
          <Box
            as="span"
            flex="1"
            textAlign="left"
            fontWeight={"bold"}
            fontSize={"18px"}
          >
            Informações do SAFI
          </Box>
          <AccordionIcon />
        </AccordionButton>

        <AccordionPanel pb={4}>
          <Styled.ContainerBoxInputs>
            <InputText
              readOnly
              label="FID:"
              name="Fid"
              value={formik.values.Fid}
            />

            <InputText
              readOnly
              label="Status SAFI:"
              name="statusSafi"
              value={formik.values.statusSafi}
            />

            <InputText
              readOnly
              label="Justificativa:"
              name="justification"
              value={formik.values.justification}
            />

            <InputText
              readOnly
              label="Data de assinatura:"
              name={"signatureDate"}
              value={formik.values.signatureDate}
            />

            <InputText
              readOnly
              label="Venda futura:"
              name={"futureSale"}
              value={formik.values.futureSale === false ? "Não" : "Sim"}
            />

            <InputText
              readOnly
              label="SICAQ:"
              name={"sicaq"}
              value={formik.values.sicaq}
            />

            <InputText
              readOnly
              label="Etapa SAFI:"
              name="stageSafi"
              value={formik.values.stageSafi}
            />
          </Styled.ContainerBoxInputs>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};

export default AccordionComponent;
