import { theme } from "src/styles/theme";
import styled from "styled-components";

export const OutlineButton = styled.div<{disabled?: boolean, iconWidth?: string}>`
  height: 40px;
  border-radius: 8px;
  border-color: ${props => props.disabled ? theme.disable : theme.primary};
  border-width: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  flex: 0;
  padding-left: 16px;
  padding-right: 16px;
  width: 100%;

  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: ${props => props.disabled ? theme.disable : theme.primary};
  flex-direction: row;

  > svg {
    width: ${props => props.iconWidth || '30px'};
    height: 24px;
  }

  > .solicitar-documento {
    width: 104px;
  }

  &:hover {
    cursor: ${props => props.disabled ? 'default' : 'pointer'};
    user-select: none;
    opacity: ${props => props.disabled ? '1.0' : '0.8'};
  }

`

export const FilledButton = styled(OutlineButton)`

  background-color: ${props => props.disabled ? theme.disable : theme.primary};
  color: white;

  > .novo-documento {
    width: 157px;
  }
  > .loading {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px;
  }


`

export const ComponentHeader = styled.div`
  display: flex;
  gap: 16px;

  > h1 {
    font-size: 13px;
    flex: 1;
    height: 40px;
    color: ${theme.text};
    font-size: 24px;
    font-weight: bold;
    padding-left: 16px;
    border-radius: 8px;
    padding-top: 5px;
    background-color: ${theme.disable100}
  }
`;

export const DataTable = styled.table`

  display: flex;
  flex: 1;
  margin-top: 16px;
  flex-direction: column;
  align-items: stretch;

  thead {
    flex: 1;
    display: flex;
  }

  tr {
  flex: 1;
  display: flex;
  align-items: stretch;
  }

  th {
    font-size: 12px;
    font-weight: normal;
    text-align: left;
  }

  .table-full {
    flex: 1;
  }

  .even-row {
    background-color: #B3B3B319;
  }

  .odd-row {
    background-color: #B3B3B34D;
  }

  .spaced-info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

`

export const DocumentDataCell = styled.div<{rejected?: boolean}>`

  margin-left: 16px;
  display: flex;
  gap: 8px;

  .icon-cell {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${props => props.rejected ? theme.error : theme.primary};
    border-radius: 8px;
  }

  .document-icon {
    width: 20px;
    height: 20px;
    color: white;
    transform: matrix(0, -1, 1, 0, 0, 0);
  }

  .document-title {
    font-weight: bold;
    color: ${theme.text};
    font-size: 14px;
    line-height: 18px;
    vertical-align: middle;
  }

  .document-origin {
    font-size: 12px;
    font-weight: normal;
    line-height: 16px;
  }
`

export const LoadingSpinner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 300px;

`;