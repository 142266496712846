import React from "react";
import { Table, Tbody, Td, Text, Th, Thead, Tr } from "@chakra-ui/react";
import * as Styled from "./styles";
import { UsuallyContext } from "@context/usuallyContex";

type TableRow = {
  idEmpreendimentoSales: string;
  nomeEmpreendimento: string;
  dataTerminoObra: string;
  statusObra: string;
  incorporadora: string;
  regional: string;
};
interface IDataTable {
  data: TableRow[];
  onClick: () => void;
  nameFilter: string;
  selectedTab: string;
}

function DataTable({
  data,
  onClick,
  nameFilter,
  selectedTab,
}: Readonly<IDataTable>) {
  const { setNameEnterprises, setIdEnterprises } =
    React.useContext(UsuallyContext);
  const [sortKey, setSortKey] = React.useState<string>("");
  const [sortOrder, setSortOrder] = React.useState<"asc" | "desc">("asc");

  const handleSort = (key: string) => {
    if (sortKey === key) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortKey(key);
      setSortOrder("asc");
    }
  };

  const handleNameClick = (
    nomeEmpreendimento: string,
    idEmpreendimentoSales: string
  ) => {
    onClick();
    setNameEnterprises(nomeEmpreendimento);
    setIdEnterprises(idEmpreendimentoSales);
    localStorage.setItem("@nomeEmpreendimento", nomeEmpreendimento);
    localStorage.setItem("@idEnterprises", idEmpreendimentoSales);
  };

  function compareValues(a: any, b: any, sortOrder: "asc" | "desc") {
    if (a === b) return 0;

    if (sortOrder === "asc") {
      return a < b ? -1 : 1;
    } else {
      return a > b ? -1 : 1;
    }
  }

  function toSorted(data: any[], sortKey: string, sortOrder: "asc" | "desc") {
    return data.sort((a, b) => {
      return compareValues(
        sortKey === "nomeEmpreendimento" ? a.nomeEmpreendimento : a[sortKey],
        sortKey === "nomeEmpreendimento" ? b.nomeEmpreendimento : b[sortKey],
        sortOrder
      );
    });
  }

  const sortedRows = toSorted(data, sortKey, sortOrder);

  const filteredRows = sortedRows?.filter(
    (row) =>
      row?.nomeEmpreendimento
        ?.toLowerCase()
        ?.includes(nameFilter?.toLowerCase()) &&
      (selectedTab === "Todos" ||
        row?.statusObra?.toUpperCase() === selectedTab)
  );

  return (
    <Table flexDirection="column" variant="simple" bg="white">
      <Thead>
        <Tr>
          <Th
            w="17%"
            onClick={() => handleSort("nomeEmpreendimento")}
            style={{ cursor: "pointer" }}
          >
            <Text fontSize={11}>
              Nome do Empreendimento{" "}
              {sortKey === "nomeEmpreendimento" &&
                (sortOrder === "asc" ? "↑" : "↓")}
            </Text>
          </Th>

          <Th w="17%" textAlign="center">
            Data término obra
          </Th>

          <Th w="17%" textAlign="center">
            Status da Obra
          </Th>

          <Th w="17%" textAlign="center">
            Incorporadora
          </Th>

          <Th w="17%" textAlign="center">
            UF
          </Th>
        </Tr>
      </Thead>
      <Tbody>
        {filteredRows.map((row) => (
          <Tr key={row.idEmpreendimentoSales}>
            <Td w="17%">
              <Styled.ContainerName
                onClick={() => {
                  handleNameClick(
                    row.nomeEmpreendimento,
                    row.idEmpreendimentoSales
                  );
                }}
              >
                <span>{row.nomeEmpreendimento}</span>
              </Styled.ContainerName>
            </Td>

            <Td w="17%">
              <Styled.ContainerDelivery>
                <span>{row.dataTerminoObra}</span>
              </Styled.ContainerDelivery>
            </Td>

            <Td w="17%">
              {row?.statusObra && (
                <Styled.ContainerStatus status={row?.statusObra?.toUpperCase()}>
                  <span>{row?.statusObra?.toUpperCase()}</span>
                </Styled.ContainerStatus>
              )}
            </Td>

            <Td w="17%">
              <Styled.ContainerDeveloper>
                <span>{row.incorporadora}</span>
              </Styled.ContainerDeveloper>
            </Td>

            <Td w="17%">
              <Styled.ContainerUf>
                <span>{row.regional}</span>
              </Styled.ContainerUf>
            </Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
}

export default DataTable;
