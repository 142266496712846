import React from "react";
import * as Styled from "../../styles";
import InputText from "@components/Sales/Inputs/InputText";
import { formatDatePtBr, phoneMask } from "@helpers/format";
import InputSelect from "@components/Sales/Inputs/InputSelect";
import {
  optionAgeRange,
  optionNationality,
  optionSex,
} from "@helpers/optionSelect";
import { isEmail } from "@helpers/emailCheck";
import { ISectionMyOportunityComponent } from "src/@types/Sales";

const SectionMyOportunityComponent = ({
  formik,
  error,
  setError,
  nacionality,
  setNacionality,
  ageGroup,
  setAgeGroup,
  sex,
  setSex,
  isOriginAccount,
}: ISectionMyOportunityComponent) => {
  const nameParts = formik.getFieldProps("nameAccount").value?.split(" ");
  const filteredNameParts = nameParts?.filter(
    (part: string) => part?.trim() !== ""
  );

  return (
    <>
      <Styled.ContainerBoxTitleInfo>
        <span>Informações principais</span>
      </Styled.ContainerBoxTitleInfo>
      <Styled.ContainerBoxInputs>
        <InputText
          isOportunity
          isRequirement
          label="Nome da Conta:"
          placeholder="Nome Completo (Nome e Sobrenome)"
          name="nameAccount"
          value={formik.values.nameAccount}
          onChange={formik.handleChange}
          error={
            filteredNameParts?.length < 2 && formik.values.nameAccount !== ""
          }
          messageError="Nome completo"
        />

        <InputText
          isTelephone
          isOportunity
          readOnly
          label="Telefone principal:"
          placeholder="(XX) XXXXX-XXXX"
          name="mainPhone"
          value={phoneMask(formik.values.mainPhone)}
        />

        <InputText
          isTelephone
          isOportunity
          label="Telefone:"
          placeholder="(XX) XXXXX-XXXX"
          name="complementaryPhone"
          value={phoneMask(formik.values.complementaryPhone)}
          onChange={formik.handleChange}
          maxLength={15}
          error={
            phoneMask(formik.values.complementaryPhone)?.length < 15 &&
            formik.values.complementaryPhone
          }
          messageError={"Formato inválido"}
        />

        <InputText
          label="Data de nascimento:"
          placeholder="XX/XX/XXXX"
          name="dateBday"
          value={formik.values.dateBday}
          onChange={(e) => {
            const newValue = formatDatePtBr(e.target.value);
            formik.handleChange(e);
            formik.setFieldValue("dateBday", newValue);
          }}
          maxLength={10}
        />

        <InputSelect
          title="Nacionalidade:"
          label={nacionality || "Selecione uma opção"}
          isSelected={nacionality}
          options={optionNationality}
          selectedOption={nacionality}
          onChange={(e) => setNacionality(e.currentTarget.value)}
        />

        <InputText
          isRequirement
          readOnly={isOriginAccount}
          label="E-mail principal:"
          placeholder="exemplo@provedor.com"
          name="mainEmail"
          value={formik.values.mainEmail}
          onChange={(e) => {
            formik.handleChange(e);
            setError((prevState: any) => {
              return {
                ...prevState,
                email: false,
              };
            });
          }}
          error={
            (!isEmail(formik.values.mainEmail) && formik.values.mainEmail) ||
            error.email
          }
          messageError={
            error.email ? "E-mail já cadastrado " : "E-mail inválido"
          }
        />

        <InputText
          label="E-mail Complementar:"
          placeholder="exemplo@provedor.com"
          name="complementaryEmail"
          value={formik.values.complementaryEmail}
          onChange={formik.handleChange}
          error={
            !isEmail(formik.values.complementaryEmail) &&
            formik.values.complementaryEmail
          }
          messageError={"E-mail inválido"}
        />

        <InputSelect
          title="Faixa etária:"
          label={ageGroup || "Selecione uma opção"}
          isSelected={ageGroup}
          options={optionAgeRange}
          selectedOption={ageGroup}
          onChange={(e) => setAgeGroup(e.currentTarget.value)}
        />
        <InputSelect
          title="Sexo:"
          label={sex || "Selecione uma opção"}
          isSelected={sex}
          options={optionSex}
          selectedOption={sex}
          onChange={(e) => setSex(e.currentTarget.value)}
        />
      </Styled.ContainerBoxInputs>
    </>
  );
};

export default SectionMyOportunityComponent;
