import { InputRadioButton } from "@components/Sales/Inputs/InputSelect";
import * as Styled from "../styles";
import { formatMoney } from "@helpers/format";

export const optionEnterprise = (
  dataEnterprises: any[],
  selectMainEnterprises: string,
  setSelectMainEnterprises: React.Dispatch<React.SetStateAction<string>>,
  setMainEnterprises: React.Dispatch<React.SetStateAction<string>>,
  setIdEnterprise: React.Dispatch<React.SetStateAction<string>>
) => {
  const handleRadioClickSearch = (
    e: React.ChangeEvent<HTMLInputElement>,
    setValue: React.Dispatch<React.SetStateAction<string>>,
    idEnterprise: string
  ): void => {
    setValue(e.currentTarget.value);
    setMainEnterprises(e.currentTarget.value);
    setIdEnterprise(idEnterprise);
  };

  const filteredRows = dataEnterprises?.filter((row) =>
    row?.nomeEmpreendimento
      ?.toLowerCase()
      ?.includes(selectMainEnterprises?.toLowerCase())
  );

  if (filteredRows.length === 0) {
    return (
      <Styled.ContainerEmptyMessage>
        <span>
          Sua pesquisa por <strong>{selectMainEnterprises}</strong> não
          correspondeu a nenhum resultado.
        </span>
      </Styled.ContainerEmptyMessage>
    );
  } else {
    return filteredRows.map(({ idEmpreendimentoSales, nomeEmpreendimento }) => (
      <InputRadioButton
        key={idEmpreendimentoSales}
        id={idEmpreendimentoSales}
        name={nomeEmpreendimento}
        checked={nomeEmpreendimento === selectMainEnterprises}
        onChange={(e) =>
          handleRadioClickSearch(
            e,
            setSelectMainEnterprises,
            idEmpreendimentoSales
          )
        }
      />
    ));
  }
};

export const optionUnity = (
  dataUnity: any[],
  selectUnity: string,
  setSelectUnity: React.Dispatch<React.SetStateAction<string>>,
  setMainEnterprises: React.Dispatch<React.SetStateAction<string>>,
  setIdUnity: React.Dispatch<React.SetStateAction<string>>
) => {
  const handleRadioClickSearch = (
    e: React.ChangeEvent<HTMLInputElement>,
    setValue: React.Dispatch<React.SetStateAction<string>>,
    idUnity: string
  ): void => {
    setValue(e.currentTarget.value);
    setMainEnterprises(e.currentTarget.value);
    setIdUnity(idUnity);
  };

  const filteredRows = dataUnity?.filter((row) =>
    row?.produtoDirecional?.toLowerCase()?.includes(selectUnity?.toLowerCase())
  );

  if (filteredRows.length === 0) {
    return (
      <Styled.ContainerEmptyMessage>
        <span>
          Sua pesquisa por <strong>{selectUnity}</strong> não correspondeu a
          nenhum resultado.
        </span>
      </Styled.ContainerEmptyMessage>
    );
  } else {
    return filteredRows.map(
      ({ idUnidadeSales, produtoDirecional, statusUnidade, valorFinalKit }) => (
        <InputRadioButton
          key={idUnidadeSales}
          id={idUnidadeSales}
          name={produtoDirecional}
          checked={produtoDirecional === selectUnity}
          onChange={(e) =>
            handleRadioClickSearch(e, setSelectUnity, idUnidadeSales)
          }
          titleDescription={`Status: ${statusUnidade} / Valor da venda: ${formatMoney(valorFinalKit)}`}
        />
      )
    );
  }
};

export const optionCreditRating = (
  dataCreditRating: any[],
  selectCreditRating: string,
  setSelectCreditRating: React.Dispatch<React.SetStateAction<string>>,
  setCreditRating: React.Dispatch<React.SetStateAction<string>>,
  setIdCreditRating: React.Dispatch<React.SetStateAction<string>>
) => {
  const handleRadioClickSearch = (
    e: React.ChangeEvent<HTMLInputElement>,
    setValue: React.Dispatch<React.SetStateAction<string>>,
    idAvaliacaoCreditoSalesforce: string
  ): void => {
    setValue(e.currentTarget.value);
    setCreditRating(e.currentTarget.value);
    setIdCreditRating(idAvaliacaoCreditoSalesforce);
  };

  const filteredRows = dataCreditRating?.filter((row) => {
    if (!dataCreditRating) return [];
    return row?.nome
      ?.toLowerCase()
      ?.includes(selectCreditRating?.toLowerCase());
  });

  if (filteredRows?.length === 0) {
    return (
      <Styled.ContainerEmptyMessage>
        <span>
          Sua pesquisa por <strong>{selectCreditRating}</strong> não
          correspondeu a nenhum resultado.
        </span>
      </Styled.ContainerEmptyMessage>
    );
  } else {
    return filteredRows?.map(({ idAvaliacaoCreditoSalesforce, nome }) => (
      <InputRadioButton
        key={idAvaliacaoCreditoSalesforce}
        id={idAvaliacaoCreditoSalesforce}
        name={nome}
        checked={nome === selectCreditRating}
        onChange={(e) =>
          handleRadioClickSearch(
            e,
            setSelectCreditRating,
            idAvaliacaoCreditoSalesforce
          )
        }
      />
    ));
  }
};

export const optionCapaign = (
  dataCampaign: any[],
  selectCampaign: string,
  setSelectCampaign: React.Dispatch<React.SetStateAction<string>>,
  setCampaign: React.Dispatch<React.SetStateAction<string>>,
  setIdCampaign: React.Dispatch<React.SetStateAction<string>>
) => {
  const handleRadioClickSearch = (
    e: React.ChangeEvent<HTMLInputElement>,
    setValue: React.Dispatch<React.SetStateAction<string>>,
    idCampaign: string
  ): void => {
    setValue(e.currentTarget.value);
    setCampaign(e.currentTarget.value);
    setIdCampaign(idCampaign);
  };

  const filteredRows = dataCampaign?.filter((row) =>
    row?.nome?.toLowerCase()?.includes(selectCampaign?.toLowerCase())
  );

  if (filteredRows.length === 0) {
    return (
      <Styled.ContainerEmptyMessage>
        <span>
          Sua pesquisa por <strong>{selectCampaign}</strong> não correspondeu a
          nenhum resultado.
        </span>
      </Styled.ContainerEmptyMessage>
    );
  } else {
    return filteredRows.map(({ idSalesforce, nome }) => (
      <InputRadioButton
        key={idSalesforce}
        id={idSalesforce}
        name={nome}
        checked={nome === selectCampaign}
        onChange={(e) =>
          handleRadioClickSearch(e, setSelectCampaign, idSalesforce)
        }
      />
    ));
  }
};

export const optionProfession = (
  dataProfession: any[],
  selectProfession: string,
  setSelectProfession: React.Dispatch<React.SetStateAction<string>>,
  setProfession: React.Dispatch<React.SetStateAction<string>>,
  setIdProfession: React.Dispatch<React.SetStateAction<string>>
) => {
  const handleRadioClickSearch = (
    e: React.ChangeEvent<HTMLInputElement>,
    setValue: React.Dispatch<React.SetStateAction<string>>,
    idProfession: string
  ): void => {
    setValue(e.currentTarget.value);
    setProfession(e.currentTarget.value);
    setIdProfession(idProfession);
  };

  const filteredRows = dataProfession?.filter((row) =>
    row?.toLowerCase()?.includes(selectProfession?.toLowerCase())
  );

  if (filteredRows.length === 0) {
    return (
      <Styled.ContainerEmptyMessage>
        <span>
          Sua pesquisa por <strong>{selectProfession}</strong> não correspondeu
          a nenhum resultado.
        </span>
      </Styled.ContainerEmptyMessage>
    );
  } else {
    return filteredRows.map((nome, index) => (
      <InputRadioButton
        key={index}
        id={index}
        name={nome}
        checked={nome === selectProfession}
        onChange={(e) =>
          handleRadioClickSearch(e, setSelectProfession, index?.toString())
        }
      />
    ));
  }
};

export const optionAccount = (
  dataAccount: any[],
  selectAccount: string,
  setSelectAccount: React.Dispatch<React.SetStateAction<string>>,
  setAccount: React.Dispatch<React.SetStateAction<string>>,
  setIdAccount: React.Dispatch<React.SetStateAction<string>>
) => {
  const handleRadioClickSearch = (
    e: React.ChangeEvent<HTMLInputElement>,
    setValue: React.Dispatch<React.SetStateAction<string>>,
    idEnterprise: string
  ): void => {
    setValue(e.currentTarget.value);
    setAccount(e.currentTarget.value);
    setIdAccount(idEnterprise);
  };

  if (dataAccount?.length === 0 || !dataAccount) {
    return (
      <Styled.ContainerEmptyMessage>
        <span>
          Sua pesquisa por <strong>{selectAccount}</strong> não correspondeu a
          nenhum resultado.
        </span>
      </Styled.ContainerEmptyMessage>
    );
  } else {
    return dataAccount?.map(({ accountIdSalesforce, nome }) => (
      <InputRadioButton
        key={accountIdSalesforce}
        id={accountIdSalesforce}
        name={nome}
        checked={nome === selectAccount}
        onChange={(e) =>
          handleRadioClickSearch(e, setSelectAccount, accountIdSalesforce)
        }
      />
    ));
  }
};

export const optionCorresponding = (
  dataCorresponding: any[],
  selectCorresponding: string,
  setSelectCorresponding: React.Dispatch<React.SetStateAction<string>>,
  setCorresponding: React.Dispatch<React.SetStateAction<string>>,
  setIdCorresponding: React.Dispatch<React.SetStateAction<string>>,
  setCorrespondingCnpj?: React.Dispatch<React.SetStateAction<string>>
) => {
  const handleRadioClickSearch = (
    e: React.ChangeEvent<HTMLInputElement>,
    setValue: React.Dispatch<React.SetStateAction<string>>,
    idEnterprise: string
  ): void => {
    setValue(e.currentTarget.value);
    setCorresponding(e.currentTarget.value);
    setIdCorresponding(idEnterprise);
  };

  const filteredRows = dataCorresponding?.filter((row) =>
    row?.nomeCorrespondente
      ?.toLowerCase()
      ?.includes(selectCorresponding?.toLowerCase())
  );

  if (filteredRows.length === 0) {
    return (
      <Styled.ContainerEmptyMessage>
        <span>
          Sua pesquisa por <strong>{selectCorresponding}</strong> não
          correspondeu a nenhum resultado.
        </span>
      </Styled.ContainerEmptyMessage>
    );
  } else {
    return filteredRows.map(({ id, nomeCorrespondente, cnpj }) => (
      <InputRadioButton
        key={id}
        id={id}
        name={nomeCorrespondente}
        checked={nomeCorrespondente === selectCorresponding}
        onChange={(e) => {
          handleRadioClickSearch(e, setSelectCorresponding, id);
          if (setCorrespondingCnpj) {
            setCorrespondingCnpj(cnpj);
          }
        }}
      />
    ));
  }
};
