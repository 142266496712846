import React from "react";
import { Input, InputGroup, InputRightElement } from "@chakra-ui/react";
import { theme } from "src/styles/theme";
import * as Styled from "./styles";
import Tooltip from "@components/common/Tooltip";

interface IInputEditable {
  label: string;
  name: string;
  placeholder?: string;
  value: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  type?: string;
  maxLength?: number;
  iconClick?: React.MouseEventHandler<SVGElement>;
  error?: boolean;
}

const InputEditable = ({
  label,
  name,
  type = "text",
  value,
  onChange,
  placeholder,
  iconClick,
  maxLength,
  error,
}: IInputEditable) => {
  return (
    <Styled.Area>
      <Styled.ContainerTop>
        {label && <Styled.Label>{label}</Styled.Label>}

        {value?.length >= 19 && (
          <Tooltip text={value}>
            <Styled.IconBsFillInfoCircleFill size="12px" />
          </Tooltip>
        )}
      </Styled.ContainerTop>

      <InputGroup>
        <Input
          placeholder={placeholder}
          name={name}
          isInvalid={error}
          value={value}
          onChange={onChange}
          type={type}
          maxLength={maxLength}
          focusBorderColor={theme.primary}
          borderWidth={2}
          borderColor={theme.disable}
          height="44px"
          borderRadius={8}
          fontSize={14}
          fontWeight="bold"
          _placeholder={{ color: theme.text }}
          _hover={{ color: "none" }}
          _focus={{ borderWidth: "1.5px" }}
          errorBorderColor={theme.error}
        />
        <InputRightElement>
          <Styled.IconMdOutlineEditNote size="32px" onClick={iconClick} />
        </InputRightElement>
      </InputGroup>
    </Styled.Area>
  );
};
export default InputEditable;
