import api from "@services/api";
import axios from "axios";

interface IConditionPayment {
  empreendimentoId: string;
}
interface IGetQuotes {
  OpportunityIdSalesforce: string;
}
interface IUpdateQuote {
  idCotacaoSalesforce: string;
  idAvaliacaoCreditoSalesforce: string;
  observacao: string | undefined;
  desconto: number | undefined;
  valorTotalTaxas: number | undefined;
  jurosTaxa: number | undefined;
  quantidadeParcelasTaxa: number | undefined;
  fgtsApurado: number | undefined;
  subsidioAvaliado: number | undefined;
  financiamentoAvaliacaoDeCredito: number | undefined;
  rendaApurada: number | undefined;
  idadeMaisVelho: number | undefined;
  taxaBanco: number | undefined;
  renda: number | undefined;
  prestacaoFinanciamento: number | undefined;
}
interface IGetQuotesById {
  IdCotacaoSalesforce: string;
}
interface ISendApproval {
  idCotacaoSalesforce: string;
}
interface ICreateQuote {
  idCotacaoSalesforce: string;
  idEmpreendimentoSales: string;
  produto: string;
  condicaoPagamentoMatrizSelecionada: string;
  idAvaliacaoCreditoSalesforce: string;
  contactIdSalesforceCorretorLogado: string;
  opportunityIdSalesforce: string;
  nomeDaCotacao: string;
}
interface IGetTypeInstallment {
  IdCondicaoDePagamentoMatriz: string;
}

interface ICreatePortionItem {
  idCotacaoSalesforce: string;
  idParcelamentoSalesforce: string;
  DataDePagamentoFormatada: string;
  nome: string;
  serie: string;
  quantidade: number;
  valor: number;
  deletado: boolean;
  tipoFluxo: string;
  percentualProSoluto: number;
}
interface ICreatePortion {
  portions: ICreatePortionItem[];
}
interface ICreateMensalPs {
  idCotacaoSalesforce: string;
  dataDePagamentoFormatada: string;
  dataDePagamento?: string;
  fluxo: string;
  months: number;
}
class QuotesService {
  async getConditionPayment({ empreendimentoId }: IConditionPayment) {
    const access_token = sessionStorage.getItem("@token");

    try {
      const response = await api.get("/cotacao/obter-condicao-pagamento", {
        params: { empreendimentoId },
        headers: {
          access_token: `${access_token}`,
        },
      });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const errorRes = error.response;
        return errorRes;
      }
    }
  }
  async getQuotes({ OpportunityIdSalesforce }: IGetQuotes) {
    const access_token = sessionStorage.getItem("@token");

    try {
      const response = await api.get("/cotacao/obter", {
        params: { OpportunityIdSalesforce },
        headers: {
          access_token: `${access_token}`,
        },
      });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const errorRes = error.response;
        return errorRes;
      }
    }
  }
  async updateQuote({
    idAvaliacaoCreditoSalesforce,
    desconto,
    idCotacaoSalesforce,
    jurosTaxa,
    quantidadeParcelasTaxa,
    valorTotalTaxas,
    fgtsApurado,
    financiamentoAvaliacaoDeCredito,
    idadeMaisVelho,
    prestacaoFinanciamento,
    renda,
    rendaApurada,
    subsidioAvaliado,
    taxaBanco,
    observacao
  }: IUpdateQuote) {
    const access_token = sessionStorage.getItem("@token");

    const body = {
      idAvaliacaoCreditoSalesforce,
      desconto,
      idCotacaoSalesforce,
      jurosTaxa,
      quantidadeParcelasTaxa,
      valorTotalTaxas,
      fgtsApurado,
      financiamentoAvaliacaoDeCredito,
      idadeMaisVelho,
      prestacaoFinanciamento,
      renda,
      rendaApurada,
      subsidioAvaliado,
      taxaBanco,
      observacao
    };

    try {
      const response = await api.patch("/cotacao/atualizar", body, {
        headers: {
          access_token: `${access_token}`,
        },
      });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const errorRes = error.response;
        return errorRes;
      }
    }
  }
  async getInstallment({ IdCotacaoSalesforce }: IGetQuotesById) {
    const access_token = sessionStorage.getItem("@token");

    try {
      const response = await api.get("/parcelamento/obter", {
        params: { IdCotacaoSalesforce },
        headers: {
          access_token: `${access_token}`,
        },
      });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const errorRes = error.response;
        return errorRes;
      }
    }
  }
  async getTypeInstallment({
    IdCondicaoDePagamentoMatriz,
  }: IGetTypeInstallment) {
    const access_token = sessionStorage.getItem("@token");

    try {
      const response = await api.get("/cotacao/obter-tipos-parcelas", {
        params: { IdCondicaoDePagamentoMatriz },
        headers: {
          access_token: `${access_token}`,
        },
      });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const errorRes = error.response;
        return errorRes;
      }
    }
  }
  async getQuotesById({ IdCotacaoSalesforce }: IGetQuotesById) {
    const access_token = sessionStorage.getItem("@token");

    try {
      const response = await api.get("/cotacao/obter-por-id", {
        params: { IdCotacaoSalesforce },
        headers: {
          access_token: `${access_token}`,
        },
      });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const errorRes = error.response;
        return errorRes;
      }
    }
  }
  async createQuote({
    idCotacaoSalesforce,
    idEmpreendimentoSales,
    produto,
    condicaoPagamentoMatrizSelecionada,
    idAvaliacaoCreditoSalesforce,
    contactIdSalesforceCorretorLogado,
    opportunityIdSalesforce,
    nomeDaCotacao,
  }: ICreateQuote) {
    const access_token = sessionStorage.getItem("@token");

    const body = {
      idCotacaoSalesforce,
      idEmpreendimentoSales,
      produto,
      condicaoPagamentoMatrizSelecionada,
      idAvaliacaoCreditoSalesforce,
      contactIdSalesforceCorretorLogado,
      opportunityIdSalesforce,
      nomeDaCotacao,
    };

    try {
      const response = await api.post("/cotacao/inserir", body, {
        headers: {
          access_token: `${access_token}`,
        },
      });

      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const errorRes = error.response;
        return errorRes;
      }
    }
  }
  async createPortion({ portions }: ICreatePortion) {
    const access_token = sessionStorage.getItem("@token");

    try {
      const response = await api.post("/parcelamento/inserir", portions, {
        headers: {
          access_token: `${access_token}`,
        },
      });

      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const errorRes = error.response;
        return errorRes;
      }
    }
  }
  async updatePortion({ portions }: ICreatePortion) {
    const access_token = sessionStorage.getItem("@token");

    try {
      const response = await api.patch("/parcelamento/atualizar", portions, {
        headers: {
          access_token: `${access_token}`,
        },
      });

      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const errorRes = error.response;
        return errorRes;
      }
    }
  }
  async createMensalPs({
    idCotacaoSalesforce,
    dataDePagamento,
    dataDePagamentoFormatada,
    fluxo,
    months,
  }: ICreateMensalPs) {
    const access_token = sessionStorage.getItem("@token");

    const body = {
      idCotacaoSalesforce,
      dataDePagamento,
      dataDePagamentoFormatada,
      fluxo,
      months,
    };

    try {
      const response = await api.post("/parcelamento/gerar-mensalps", body, {
        headers: {
          access_token: `${access_token}`,
        },
      });

      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const errorRes = error.response;
        return errorRes;
      }
    }
  }
  async sendToApproval({ idCotacaoSalesforce }: ISendApproval) {
    const access_token = sessionStorage.getItem("@token");

    try {
      const response = await api.patch("/cotacao/enviar-para-aprovacao", null, {
        params: { idCotacaoSalesforce },
        headers: {
          access_token: `${access_token}`,
        },
      });

      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const errorRes = error.response;
        return errorRes;
      }
    }
  }
}

const quotesServiceInstance = new QuotesService();
export default quotesServiceInstance;
