import React from "react";
import * as Styled from "../../styles";
import Button from "@components/common/Button";
import { IOportunityComponents, ValuesFormikCreateMyOportunityComponent } from "src/@types/Sales";
import { useFormik } from "formik";
import { isEmail } from "@helpers/emailCheck";
import { LoginContext } from "@context/loginContext";
import enterprisesServiceInstance from "@services/Enterprises/enterprisesService";
import Alert from "@components/common/Alert";
import salesService from "@services/Sales/SalesService";
import Spinner from "@components/common/Spinner";
import { BsFillPersonVcardFill } from "react-icons/bs";
import SectionMyOportunityComponent from "../../components/SectionMyOportunity/SectionMyOportunityComponent";
import SectionProductInterestComponent from "../../components/SectionMyOportunity/SectionProductInterestComponent";
import { phoneMask } from "@helpers/format";
import { UsuallyContext } from "@context/usuallyContex";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { OportunityContext } from "@context/oportunityContex";
import ToastNotify from "@components/Sales/ToastNotify";
import { getDateOrNull } from "../../components/AccordionCreditRatingComponent";

const CreateMyOportunityComponent = ({
  onBack,
  navigation,
}: IOportunityComponents) => {
  const regionalUser: any = localStorage.getItem("@regional");
  const idCorretor: any = localStorage.getItem("@idCorretor");
  const user: any = localStorage.getItem("@user");

  const { signOut } = React.useContext(LoginContext);
  const {
    setSuccessCreateOportunity,
    setNameCreateOportunity,
    accountIdSalesforce,
  } = React.useContext(UsuallyContext);
  const { origemConta, setAccessCreateOp } =
    React.useContext(OportunityContext);
  const [isUnauthorized, setIsUnauthorized] = React.useState(false);
  const [require, setRequire] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [loadingOportunity, setLoadingOportunity] = React.useState(false);
  const [loadingEnterprises, setLoadingEnterprises] = React.useState(false);
  const [dataEnterprises, setDataEnterprises] = React.useState([]);
  const [loadingCampaign, setLoadingCampaign] = React.useState(false);
  const [dataCampaign, setDataCampaign] = React.useState([]);
  const [idEnterprises, setIdEnterprises] = React.useState("");
  const [idCampaign, setIdCampaign] = React.useState("");
  const [ageGroup, setAgeGroup] = React.useState("");
  const [nacionality, setNacionality] = React.useState("");
  const [sex, setSex] = React.useState("");
  const [error, setError] = React.useState({
    email: false,
    telefone: false,
  });
  const [errorOportunity, setErrorOportunity] = React.useState("");
  const [showErrorOportunity, setShowErrorOportunity] = React.useState(false);
  const [campaign, setCampaign] = React.useState("");
  const [selectCampaign, setSelectCampaign] = React.useState("");
  const [mainEnterprises, setMainEnterprises] = React.useState("");
  const [selectMainEnterprises, setSelectMainEnterprises] = React.useState("");
  const [isOriginAccount, setIsOriginAccount] = React.useState(false);
  const [data, setData] = React.useState<any>([]);

  const initialValues = {
    nameAccount: data?.nome || "",
    mainPhone: data?.telefone || "",
    mainEmail: data?.email || "",
    complementaryPhone: data?.telefoneComplementar || "",
    complementaryEmail: data?.emailComplementar || "",
    comments: "",
    dateBday: data?.dataNascimento || "",
  };

  const formik: any = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: async (values) => handleSubmitFormik(values),
  });

  const handleSubmitFormik = async (value: ValuesFormikCreateMyOportunityComponent) => {
    setLoading(true);
    setShowErrorOportunity(false);
    setSuccessCreateOportunity(false);

    try {
      const response = await salesService.createOportunity({
        regional: regionalUser,
        accountIdSalesforce,
        idCorretorSalesforce: idCorretor,
        fase: null,
        origemConta,
        nomeDaConta: value?.nameAccount,
        nomeCorretor: user,
        telefonePrincipal: phoneMask(value?.mainPhone),
        dataNascimento: value?.dateBday,
        nacionalidade: nacionality,
        emailPrincipal: value?.mainEmail,
        emailComplementar: value?.complementaryEmail,
        faixaEtaria: ageGroup,
        sexo: sex,
        empreendimentoDeInteressePrincipal: idEnterprises,
        campanha: idCampaign,
        telefoneComplementar: value?.complementaryPhone,
      });

      if (
        response?.status === 400 &&
        response?.data
          ?.toLowerCase()
          ?.includes("fase superior a visita realizada")
      ) {
        setErrorOportunity(
          "Não é possível criar a oportunidade pois já existe outra com fase superior a Visita realizada."
        );
        setShowErrorOportunity(true);
        setLoading(false);
        return;
      }
      if (
        response?.status === 400 &&
        response?.data?.toLowerCase()?.includes("parâmetros informados")
      ) {
        setErrorOportunity(
          "Não é possível criar a oportunidade com os parâmetros informados."
        );
        setShowErrorOportunity(true);
        setLoading(false);
        return;
      }

      if (
        response?.status === 400 &&
        (!response?.data
          ?.toLowerCase()
          ?.includes("fase superior a visita realizada") ||
          !response?.data?.toLowerCase()?.includes("parâmetros informados"))
      ) {
        setError((prevState) => ({
          ...prevState,
          email: response?.data.emailJaUtilizado,
          telefone: response?.data.telefoneJaUtilizado,
        }));

        setLoading(false);
        return;
      }

      if (response?.status === 401) {
        setLoading(false);
        setIsUnauthorized(true);
        return;
      }

      setAccessCreateOp({
        oportunidadeId: response?.oportunidadeId,
        oportunityIdSalesforce: response?.id,
        nomeDaConta: response?.nomeDaConta,
      });
      setNameCreateOportunity(value?.nameAccount);
      setSuccessCreateOportunity(true);
      navigation();
      setLoading(false);
    } catch (error) {
      console.log("ErrorFormik", error);
      setLoading(false);
    }
  };

  const handleGetEnterprises = async () => {
    setLoadingEnterprises(true);

    try {
      const response = await enterprisesServiceInstance.getEnterprises({
        regional: regionalUser,
      });

      if (response.status >= 400 && response?.status !== 401) {
        setLoadingEnterprises(false);
        setDataEnterprises([]);
        console.log("status 400", response);
        return;
      }
      if (response.status === 401) {
        setLoadingEnterprises(false);
        setIsUnauthorized(true);
        setDataEnterprises([]);
        return;
      }

      const dataArray: any = Object.values(response);

      setDataEnterprises(dataArray);
      setLoadingEnterprises(false);
    } catch (error) {
      setLoadingEnterprises(false);
      console.log("errorHandleGetEnterprises", error);
    }
  };

  const handleGetCampaign = async () => {
    setLoadingCampaign(true);

    try {
      const response = await salesService.getCampaign();

      if (response.status >= 400 && response?.status !== 401) {
        setLoadingCampaign(false);
        setDataCampaign([]);
        console.log("status 400", response);
        return;
      }
      if (response.status === 401) {
        setLoadingCampaign(false);
        setIsUnauthorized(true);
        setDataCampaign([]);
        return;
      }

      const dataArray: any = Object.values(response);

      setDataCampaign(dataArray);
      setLoadingCampaign(false);
    } catch (error) {
      setLoadingCampaign(false);
      console.log("errorHandleGetEnterprises", error);
    }
  };

  const nameParts = formik.getFieldProps("nameAccount").value?.split(" ");
  const filteredNameParts = nameParts?.filter(
    (part: string) => part?.trim() !== ""
  );

  React.useEffect(() => {
    const handleGetOportunityById = async () => {
      setLoadingOportunity(true);
      try {
        const response = await salesService.getAccountById({
          accountIdSalesforce,
        });

        if (response?.status === 400) {
          setError((prevState) => ({
            ...prevState,
            email: response?.data.emailJaUtilizado,
            telefone: response?.data.telefoneJaUtilizado,
          }));

          setLoadingOportunity(false);
          return;
        }
        if (response?.status === 401) {
          setLoadingOportunity(false);
          setIsUnauthorized(true);
          return;
        }

        setData(response);
        setIsOriginAccount(response?.origemConta === "Digital");
        setNacionality(response?.nacionalidade || "");
        setAgeGroup(response?.faixaEtaria || "");
        setSex(response?.sexo || "");

        if (response?.dataNascimento) {
          formik.setValue("dateBday", getDateOrNull(response?.dataNascimento));
        }
        setLoadingOportunity(false);
      } catch (error) {
        console.log("ErrorHandleGetOportunityById", error);
        setLoadingOportunity(false);
      }
    };

    handleGetOportunityById();
  }, [accountIdSalesforce]);

  React.useEffect(() => {
    const isRequirement =
      !formik.getFieldProps("nameAccount").value ||
      (filteredNameParts?.length ?? 0) < 2 ||
      !formik.getFieldProps("mainEmail").value ||
      (!isEmail(formik.getFieldProps("mainEmail").value) &&
        formik.getFieldProps("mainEmail").value) ||
      (phoneMask(formik.getFieldProps("complementaryPhone").value)?.length <
        15 &&
        formik.getFieldProps("complementaryPhone").value) ||
      (!isEmail(formik.getFieldProps("complementaryEmail").value) &&
        formik.getFieldProps("complementaryEmail").value);

    setRequire(isRequirement);
  }, [filteredNameParts, formik]);

  return (
    <>
      <Alert
        onOpen={isUnauthorized}
        title="Erro ao carregar os dados!"
        titleButton={"Logar novamente"}
        description={
          "Sua sessão expirou, faça login novamente para ter acesso."
        }
        onClick={() => {
          signOut();
        }}
      />
      <ToastNotify
        height="190px"
        onOpen={showErrorOportunity}
        title={"Não foi possível criar uma oportunidade."}
        message={errorOportunity}
        titleButton={"VOLTAR"}
        onClick={() => setShowErrorOportunity(false)}
      />

      <Styled.ContainerTitlePage>
        <Styled.ContainerTitle>
          <BsFillPersonVcardFill />
          <Styled.TitlePage>Minhas oportunidades</Styled.TitlePage>
        </Styled.ContainerTitle>
      </Styled.ContainerTitlePage>

      <Styled.ContainerBoxAccounts>
        <Styled.ContainerTitleBox>
          <Styled.ContainerTitleWithCloseButton>
            <span>Criar nova oportunidade</span>
          </Styled.ContainerTitleWithCloseButton>

          <Styled.IconAiOutlineCloseCircle onClick={onBack} />
        </Styled.ContainerTitleBox>

        {loadingOportunity ? (
          <Styled.ContainerBoxSpinner>
            <Spinner />
          </Styled.ContainerBoxSpinner>
        ) : (
          <>
            <Styled.ContainerBoxInfo>
              <SectionMyOportunityComponent
                formik={formik}
                isOriginAccount={isOriginAccount}
                error={error}
                setError={setError}
                ageGroup={ageGroup}
                setAgeGroup={setAgeGroup}
                nacionality={nacionality}
                setNacionality={setNacionality}
                sex={sex}
                setSex={setSex}
              />
            </Styled.ContainerBoxInfo>

            <Styled.ContainerBoxInfoProduct>
              <SectionProductInterestComponent
                setIdCampaign={setIdCampaign}
                dataCampaign={dataCampaign}
                getCampaign={handleGetCampaign}
                campaign={campaign}
                setCampaign={setCampaign}
                selectCampaign={selectCampaign}
                setSelectCampaign={setSelectCampaign}
                loadingCampaign={loadingCampaign}
                dataEnterprises={dataEnterprises}
                getEnterprises={handleGetEnterprises}
                selectMainEnterprises={selectMainEnterprises}
                setSelectMainEnterprises={setSelectMainEnterprises}
                mainEnterprises={mainEnterprises}
                setIdEnterprise={setIdEnterprises}
                setMainEnterprises={setMainEnterprises}
                loadingEnterprises={loadingEnterprises}
              />
            </Styled.ContainerBoxInfoProduct>

            <Styled.DividerBoxInput />

            <Styled.ContainerFooterBox>
              <Styled.ContainerDuoMandatory>
                <span>(*) Campo de preenchimento obrigatório</span>
                <span>
                  (<AiOutlineInfoCircle />) O campo apresenta formato ou dado
                  inválido
                </span>
              </Styled.ContainerDuoMandatory>

              <Styled.ContainerButton>
                <Button error title="CANCELAR" onClick={onBack} />

                <Button
                  title={loading ? <Spinner /> : "CRIAR OPORTUNIDADE"}
                  onClick={formik.handleSubmit}
                  disabled={require || loading}
                />
              </Styled.ContainerButton>
            </Styled.ContainerFooterBox>
          </>
        )}
      </Styled.ContainerBoxAccounts>
    </>
  );
};

export default CreateMyOportunityComponent;
