import api from "@services/api";
import axios, { AxiosError } from "axios";
import {
  ICreateCreditRating,
  ICreateRelationship,
  ICreateSchedule,
  ICreateTask,
  IDeleteRealtionship,
  IGetCreditRating,
  IGetCreditRatingById,
  IGetOnboardingDocuments,
  IGetOportunityById,
  IGetPdv,
  IGetRealtionship,
  IGetSchedule,
  IGetScheduleById,
  IGetTaskById,
  IGetTasks,
  IUpdateRelationship,
  IUpdateSchedule,
  IUpdateTask,
  IgetCreditRating,
} from "src/@types/Oportunity";

interface IUpdateDocumentStatus {
  NomeDocumento: string;
  Status: string;
}
interface IUpdateDocumentStatus {
  NomeDocumento: string;
  Status: string;
}
interface IUpdateDocumentStatus {
  NomeDocumento: string;
  Status: string;
  Justificativa: string;
}
interface ISendUploadLink {
  email: string;
  nome: string;
  telefone: string;
  OportunidadeId: string;
}

interface IUpdateCreditRating {
  idAvaliacaoCreditoSalesforce: string;
  valorEstimadoCompraEVenda: string;
  idEmpreendimentoSales: string;
  observacoes: string;
  acAtiva: boolean;
  banco: string;
  nomeCorrespondente: string | null;
  CNPJCorrespondente: string | null;
}

export interface IOnboardingDocument {
  origem: string;
  tipoDocumento: string;
  nomeArquivo: string;
  descricao: string;
  statusDocumento: string;
  justificativa: string | null;
  dataUpload: string;
  url: string;
  idAws: string;
}

export interface IContractData {
  idDocumentoPrincipal: string;
  nome: string;
  status: string;
  dataUltimaAtualizacao: string;
  dataCriacao: string;
  assinantesObrigatoriosFinalizados: boolean;
}

interface ISAFIData {
  idEmpreendimentoSales: string;
  codigoBanco: number | null;
  fid: number;
  idAvaliacaoCreditoSalesforce: string;
  opportunityIdSalesforce: string;
  imobiliaria: string;
  valorEstimadoCompraEVenda: number;
  observacao: string;
  status: string;
  CNPJCorrespondente: string | null;
}

interface IUpdateOportunity {
  opportunityIdSalesforce: string;
  OportunidadeId?: string;
  origemDaConta?: string| null;
  NomeConta?: string| null;
  accountIdSalesforce: string | null;
  telefoneComplementar?: string | null;
  emailComplementar?: string | null;
  emailPrincipal?: string | null;
  dataNascimento?: string | null;
  nacionalidade?: string | null;
  faixaEtaria?: string | null;
  sexo?: string | null;
  cpf?: string | null;
  rg?: string | null;
  dataExpedicaoRG?: string | null;
  orgaoExpedidorRG?: string | null;
  profissao?: string | null;
  estadoCivil?: string | null;
  dataCasamento?: string | null;
  regimeCasamento?: string | null;
  empreendimentoDeInteressePrincipal?: string | null;
  campanha?: string | null;
  tipoVenda?: string | null;
  observacao?: string | null;
}

interface IGetCorrespondingRow {
  regional: string;
  unidadeDeNegocio: string;
  regiao: string | null;
}

class OportunityService {
  async getOportunityById({ OportunidadeId }: IGetOportunityById) {
    const access_token = sessionStorage.getItem("@token");

    try {
      const response = await api.get("/oportunidade/obter-por-id", {
        params: { OportunidadeId },
        headers: {
          access_token: `${access_token}`,
        },
      });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const errorRes = error.response;
        return errorRes;
      }
    }
  }

  async updateOportunity(payload: IUpdateOportunity) {
    const access_token = sessionStorage.getItem("@token");

    try {
      await api.patch("/oportunidade/atualizar", payload, {
        headers: {
          access_token: `${access_token}`,
        },
      });

      return { success: true, err: "" };
    } catch (err) {
      if (err instanceof AxiosError) {
        if (err.response !== undefined) {
          return { success: false, err: err.response!.data as string };
        }
      }
      return {
        success: false,
        err: "Ocorreu algum erro, tente novamente mais tarde",
      };
    }
  }

  async getContracts({
    opportunityIdSalesforce,
  }: {
    opportunityIdSalesforce: string;
  }) {
    const access_token = sessionStorage.getItem("@token");

    try {
      const response = await api.get<IContractData[]>(
        "/oportunidade/obter-contratos",
        {
          params: { OportunidadeId: opportunityIdSalesforce },
          headers: {
            access_token: `${access_token}`,
          },
        }
      );

      if (response.status === 204) return [];
      return response.data;
    } catch (error) {
      return [];
    }
  }

  async getContractURL(documentId: string) {
    const access_token = sessionStorage.getItem("@token");

    try {
      const response = await api.get<{ url: string }>(
        "/oportunidade/download-contrato",
        {
          params: { documentId },
          headers: {
            access_token: `${access_token}`,
          },
        }
      );

      return response.data.url;
    } catch (error) {
      return "";
    }
  }

  async getOnboardingDocuments({
    IdRelacionamentoComprador,
  }: IGetOnboardingDocuments) {
    const access_token = sessionStorage.getItem("@token");

    try {
      const response = await api.get<IOnboardingDocument[]>(
        "/oportunidade/obter-documentos",
        {
          params: { IdRelacionamentoComprador },
          headers: {
            access_token: `${access_token}`,
          },
        }
      );

      if (response.status === 204) return [];
      return response.data;
    } catch (error) {
      return [];
    }
  }

  async updateDocumentStatus({
    NomeDocumento,
    Status,
    Justificativa,
  }: IUpdateDocumentStatus) {
    const access_token = sessionStorage.getItem("@token");

    try {
      await api.patch(
        "/oportunidade/atualiza-status-documento",
        {},
        {
          params: {
            NomeDocumento,
            Status,
            Justificativa,
          },
          headers: {
            access_token: `${access_token}`,
          },
        }
      );
      return true;
    } catch {
      return false;
    }
  }

  async sendOnboardingLink(params: ISendUploadLink) {
    const access_token = sessionStorage.getItem("@token");

    try {
      await api.post(
        "/oportunidade/enviar-link",
        {},
        {
          params,
          headers: {
            access_token: `${access_token}`,
          },
        }
      );

      return true;
    } catch {
      return false;
    }
  }

  async uploadDocument(data: FormData) {
    const access_token = sessionStorage.getItem("@token");

    try {
      await api.post("/oportunidade/post-documento", data, {
        headers: {
          access_token: `${access_token}`,
        },
      });

      return true;
    } catch {
      return false;
    }
  }

  async deleteDocument(idAws: string) {
    const access_token = sessionStorage.getItem("@token");

    try {
      await api.delete("/oportunidade/deletar-documento-s3", {
        params: { idAws },
        headers: {
          access_token: `${access_token}`,
        },
      });

      return true;
    } catch {
      return false;
    }
  }

  async getSchedule({
    ContactIdSalesforce,
    OpportunityIdSalesforce,
  }: IGetSchedule) {
    const access_token = sessionStorage.getItem("@token");

    try {
      const response = await api.get("/agendamento/obter", {
        params: { ContactIdSalesforce, OpportunityIdSalesforce },
        headers: {
          access_token: `${access_token}`,
        },
      });

      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const errorRes = error.response;
        return errorRes;
      }
    }
  }

  async deleteSchedule(IdAgendamento: string) {
    const access_token = sessionStorage.getItem("@token");

    try {
      const response = await api.delete("/agendamento/deletar", {
        params: { IdAgendamento },
        headers: {
          access_token: `${access_token}`,
        },
      });

      return response.data;
    } catch (error: any) {
      if (axios.isAxiosError(error)) {
        const errorRes = error.response;
        return errorRes;
      }
      return false;
    }
  }

  async updateSchedule({
    idAgendamentoSalesforce,
    observacoes,
  }: IUpdateSchedule) {
    const access_token = sessionStorage.getItem("@token");

    const body = {
      idAgendamentoSalesforce,
      observacoes,
    };

    try {
      const response = await api.patch("/agendamento/atualizar", body, {
        headers: {
          access_token: `${access_token}`,
        },
      });

      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const errorRes = error.response;
        return errorRes;
      }
    }
  }

  async getScheduleById({ idAgendamentoSalesforce }: IGetScheduleById) {
    const access_token = sessionStorage.getItem("@token");

    try {
      const response = await api.get("/agendamento/obter-por-id", {
        params: { idAgendamentoSalesforce },
        headers: {
          access_token: `${access_token}`,
        },
      });

      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const errorRes = error.response;
        return errorRes;
      }
    }
  }

  async getPdv({ regional }: IGetPdv) {
    const access_token = sessionStorage.getItem("@token");

    try {
      const response = await api.get("/agendamento/obter-pdvs", {
        params: { regional },
        headers: {
          access_token: `${access_token}`,
        },
      });

      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const errorRes = error.response;
        return errorRes;
      }
    }
  }

  async createSchedule({
    imobiliaria,
    pdv,
    relativoA,
    tipoDeVisita,
    dataAgendamento,
    horaAgendamento,
    empreendimentoDeInteresse,
    unidadeDeNegocio,
    regional,
    observacoes,
    contactIdSalesforce,
  }: ICreateSchedule) {
    const access_token = sessionStorage.getItem("@token");

    const body = {
      imobiliaria,
      pdv,
      relativoA,
      tipoDeVisita,
      dataAgendamento,
      horaAgendamento,
      empreendimentoDeInteresse,
      unidadeDeNegocio,
      regional,
      observacoes,
      contactIdSalesforce,
    };

    try {
      const response = await api.post("/agendamento/inserir", body, {
        headers: {
          access_token: `${access_token}`,
        },
      });

      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const errorRes = error.response;
        return errorRes;
      }
    }
  }

  async getTasks({ OpportunityIdSalesforce }: IGetTasks) {
    const access_token = sessionStorage.getItem("@token");

    try {
      const response = await api.get("/tarefa/obter", {
        params: { OpportunityIdSalesforce },
        headers: {
          access_token: `${access_token}`,
        },
      });

      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const errorRes = error.response;
        return errorRes;
      }
    }
  }

  async deleteTasks(IdTarefa: string) {
    const access_token = sessionStorage.getItem("@token");

    try {
      const response = await api.delete("/tarefa/deletar", {
        params: { IdTarefa },
        headers: {
          access_token: `${access_token}`,
        },
      });

      return response.data;
    } catch (error: any) {
      if (axios.isAxiosError(error)) {
        const errorRes = error.response;
        return errorRes;
      }
      return false;
    }
  }

  async getTasksById({ idTarefaSalesforce }: IGetTaskById) {
    const access_token = sessionStorage.getItem("@token");

    try {
      const response = await api.get("/tarefa/obter-por-id", {
        params: { idTarefaSalesforce },
        headers: {
          access_token: `${access_token}`,
        },
      });

      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const errorRes = error.response;
        return errorRes;
      }
    }
  }

  async createTask({
    assunto,
    idCorretorSalesforce,
    relativoA,
    dataDeVencimento,
    status,
    tipoDeRegistro,
    comentarios,
    numeroDeTentativas,
  }: ICreateTask) {
    const access_token = sessionStorage.getItem("@token");

    const body = {
      assunto,
      idCorretorSalesforce,
      relativoA,
      dataDeVencimento,
      status,
      tipoDeRegistro,
      comentarios,
      numeroDeTentativas,
    };

    try {
      const response = await api.post("/tarefa/inserir", body, {
        headers: {
          access_token: `${access_token}`,
        },
      });

      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const errorRes = error.response;
        return errorRes;
      }
    }
  }

  async updateTask({
    idTarefaSalesforce,
    numeroDeTentativas,
    comentarios,
    status,
    assunto,
    dataVencimento,
  }: IUpdateTask) {
    const access_token = sessionStorage.getItem("@token");

    const body = {
      idTarefaSalesforce,
      numeroDeTentativas,
      comentarios,
      status,
      assunto,
      dataVencimento,
    };

    try {
      const response = await api.patch("/tarefa/atualizar", body, {
        headers: {
          access_token: `${access_token}`,
        },
      });

      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const errorRes = error.response;
        return errorRes;
      }
    }
  }

  async getCreditRating({
    OpportunityIdSalesforce,
    somenteAprovadas,
  }: IgetCreditRating) {
    const access_token = sessionStorage.getItem("@token");

    try {
      const response = await api.get("/analise-de-credito/obter", {
        params: { OpportunityIdSalesforce, somenteAprovadas },
        headers: {
          access_token: `${access_token}`,
        },
      });

      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const errorRes = error.response;
        return errorRes;
      }
    }
  }

  async getCreditRatingById({
    IdAvaliacaoCreditoSalesforce,
  }: IGetCreditRating) {
    const access_token = sessionStorage.getItem("@token");

    try {
      const response = await api.get("/analise-de-credito/obter-por-id", {
        params: { IdAvaliacaoCreditoSalesforce },
        headers: {
          access_token: `${access_token}`,
        },
      });

      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const errorRes = error.response;
        return errorRes;
      }
    }
  }

  async createCreditRating({
    opportunityIdSalesforce,
    acAtiva,
    accountIdSalesforce,
    idEmpreendimentoSales,
    imobiliariaId,
    nomeBanco,
    observacoes,
    valorEstimadoCompraEVenda,
    vendaFutura,
    nomeCorrespondente,
    cnpjCorrespondente,
  }: ICreateCreditRating) {
    const access_token = sessionStorage.getItem("@token");

    const body = {
      opportunityIdSalesforce,
      acAtiva,
      accountIdSalesforce,
      idEmpreendimentoSales,
      imobiliariaId,
      nomeBanco,
      observacoes,
      valorEstimadoCompraEVenda,
      vendaFutura,
      nomeCorrespondente,
      cnpjCorrespondente,
    };

    try {
      const response = await api.post("/analise-de-credito/inserir", body, {
        headers: {
          access_token: `${access_token}`,
        },
      });

      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const errorRes = error.response;
        return errorRes;
      }
    }
  }

  async updateCreditRating({
    acAtiva,
    banco,
    idAvaliacaoCreditoSalesforce,
    idEmpreendimentoSales,
    observacoes,
    valorEstimadoCompraEVenda,
    nomeCorrespondente,
    CNPJCorrespondente,
  }: IUpdateCreditRating) {
    const access_token = sessionStorage.getItem("@token");

    const body = {
      acAtiva,
      banco,
      idAvaliacaoCreditoSalesforce,
      idEmpreendimentoSales,
      observacoes,
      valorEstimadoCompraEVenda,
      nomeCorrespondente,
      CNPJCorrespondente,
    };

    try {
      const response = await api.patch("/analise-de-credito/atualizar", body, {
        headers: {
          access_token: `${access_token}`,
        },
      });

      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const errorRes = error.response;
        return errorRes;
      }
    }
  }

  async getRealtionship({ OpportunityIdSalesforce }: IGetRealtionship) {
    const access_token = sessionStorage.getItem("@token");

    try {
      const response = await api.get("/relacionamento-comprador/obter", {
        params: { OpportunityIdSalesforce },
        headers: {
          access_token: `${access_token}`,
        },
      });

      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const errorRes = error.response;
        return errorRes;
      }
    }
  }

  async getRealtionshipById({
    IdRelacionamentoComprador,
  }: IGetCreditRatingById) {
    const access_token = sessionStorage.getItem("@token");

    try {
      const response = await api.get("/relacionamento-comprador/obter-por-id", {
        params: { IdRelacionamentoComprador },
        headers: {
          access_token: `${access_token}`,
        },
      });

      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const errorRes = error.response;
        return errorRes;
      }
    }
  }

  async createRelationship({
    accountIdSalesforce,
    cpf,
    idRelacionamentoComprador,
    oportunidade,
    tipoRelacionamento,
    contactIdSalesforceCorretorLogado,
    idAvaliacaoCreditoSalesforce,
  }: ICreateRelationship) {
    const access_token = sessionStorage.getItem("@token");

    const body = {
      accountIdSalesforce,
      cpf,
      idRelacionamentoComprador,
      oportunidade,
      tipoRelacionamento,
      contactIdSalesforceCorretorLogado,
      idAvaliacaoCreditoSalesforce,
    };

    try {
      const response = await api.post(
        "/relacionamento-comprador/inserir",
        body,
        {
          headers: {
            access_token: `${access_token}`,
          },
        }
      );

      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const errorRes = error.response;
        return errorRes;
      }
    }
  }

  async updateRelationship({
    accountIdSalesforce,
    cpf,
    idRelacionamentoComprador,
    oportunidade,
    tipoRelacionamento,
  }: IUpdateRelationship) {
    const access_token = sessionStorage.getItem("@token");

    const body = {
      accountIdSalesforce,
      cpf,
      idRelacionamentoComprador,
      oportunidade,
      tipoRelacionamento,
    };

    try {
      const response = await api.patch(
        "/relacionamento-comprador/atualizar",
        body,
        {
          headers: {
            access_token: `${access_token}`,
          },
        }
      );

      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const errorRes = error.response;
        return errorRes;
      }
    }
  }

  async deleteRealtionship({ IdRelacionamentoComprador  }: IDeleteRealtionship) {
    const access_token = sessionStorage.getItem("@token");

    try {
      const response = await api.delete("/relacionamento-comprador/deletar", {
        params: { IdRelacionamentoComprador  },
        headers: {
          access_token: `${access_token}`,
        },
      });

      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const errorRes = error.response;
        return errorRes;
      }
    }
  }

  async checkDocuments(OpportunityIdSalesforce: string) {
    const access_token = sessionStorage.getItem("@token");

    try {
      await api.get("/analise-de-credito/VerificaDocumentos", {
        params: { OpportunityIdSalesforce },
        headers: {
          access_token: `${access_token}`,
        },
      });

      return true;
    } catch {
      return false;
    }
  }

  async sendToSAFI(body: ISAFIData) {
    const access_token = sessionStorage.getItem("@token");

    try {
      const response = await api.post<{
        descricaoRetorno: string;
        tipoRetorno: number;
        codigoRetorno: number;
      }>("/analise-de-credito/SAFI", body, {
        headers: {
          access_token: `${access_token}`,
        },
      });

      return {
        descricaoRetorno: response.data.descricaoRetorno,
        tipoRetorno: response.data.tipoRetorno,
        codigoRetorno: response.data.codigoRetorno,
      };
    } catch (error) {
      return {
        descricaoRetorno: "",
        tipoRetorno: 1,
        codigoRetorno: 1,
      };
    }
  }

  async getCorresponding() {
    const access_token = sessionStorage.getItem("@token");

    try {
      const response = await api.get("/analise-de-credito/Correspondentes", {
        headers: {
          access_token: `${access_token}`,
        },
      });

      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const errorRes = error.response;
        return errorRes;
      }
      return false;
    }
  }
  async getUrlS3(idAws: string) {
    const access_token = sessionStorage.getItem("@token");

    try {
      const response = await api.get(
        "/relacionamento-comprador/obter-url-documento-S3",
        {
          params: { idAws },
          headers: {
            access_token: `${access_token}`,
          },
        }
      );

      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const errorRes = error.response;
        return errorRes;
      }
      return false;
    }
  }
  async getProfission() {
    const access_token = sessionStorage.getItem("@token");

    try {
      const response = await api.get("/conta/obter-profissoes", {
        headers: {
          access_token: `${access_token}`,
        },
      });

      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const errorRes = error.response;
        return errorRes;
      }
      return false;
    }
  }
  async getCorrespondingRow({
    regional,
    unidadeDeNegocio,
    regiao,
  }: IGetCorrespondingRow) {
    const access_token = sessionStorage.getItem("@token");

    const body = {
      regional,
      unidadeDeNegocio,
      regiao,
    };

    try {
      const response = await api.post(
        "/analise-de-credito/VerificaExisteFilaFilial",
        body,
        {
          headers: {
            access_token: `${access_token}`,
          },
        }
      );

      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const errorRes = error.response;
        return errorRes;
      }
      return false;
    }
  }
}

const oportunityServiceInstance = new OportunityService();
export default oportunityServiceInstance;
