import React from "react";
import { IUpdateDataClientComponent } from "src/@types/Sales";
import * as Styled from "../Steps/stylesView&Create";
import Alert from "@components/common/Alert";
import Spinner from "@components/common/Spinner";
import Button from "@components/common/Button";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { LoginContext } from "@context/loginContext";
import InputText from "@components/Sales/Inputs/InputText";
import InputDate from "@components/Sales/Inputs/InputDate";
import InputSelect from "@components/Sales/Inputs/InputSelect";
import {
  optionCivilStatus,
  optionMarriageRegime,
  optionNationality,
} from "@helpers/optionSelect";
import { documentMask, rgMask } from "@helpers/format";
import oportunityServiceInstance from "@services/Oportunity/OportunityService";
import { OportunityContext } from "@context/oportunityContex";
import { useMessageErrorToast } from "@hook/useMessageErrorToast";
import { format, parse } from "date-fns";
import { useOnlyMessageToast } from "@hook/useMessageToast";
import { ptBR } from "date-fns/locale";
import { optionProfession } from "./options";
import InputSelectSearch from "@components/Sales/Inputs/InputSelectSearch";
import salesServiceInstance from "@services/Sales/SalesService";
import { isEmail } from "@helpers/emailCheck";

const UpdateDataClientComponent = ({
  onBack,
  onSuccess,
  isRequired = "",
  loadindSuccess,
}: IUpdateDataClientComponent) => {
  const displayMessage = useOnlyMessageToast();
  const displayMessageError = useMessageErrorToast();
  const { signOut } = React.useContext(LoginContext);
  const { accessCreateOp, accountIdSalesforce } =
    React.useContext(OportunityContext);
  const [isUnauthorized, setIsUnauthorized] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [loadingGetOportunity, setLoadingGetOportunity] = React.useState(false);
  const [name, setName] = React.useState("");
  const [cpf, setCpf] = React.useState("");
  const [rg, setRg] = React.useState("");
  const [dispatcherOrg, setDispatcherOrg] = React.useState("");
  const [dateIssue, setDateIssue] = React.useState("");
  const [dateBirth, setDateBirth] = React.useState("");
  const [nationality, setNationality] = React.useState("");
  const [civilStatus, setCivilStatus] = React.useState("");
  const [require, setRequire] = React.useState(false);
  const [profession, setProfession] = React.useState("");
  const [selectProfession, setSelectProfession] = React.useState("");
  const [loadingProfession, setLoadingProfession] = React.useState(false);
  const [dataProfession, setDataProfession] = React.useState([]);
  const [idProfession, setIdProfession] = React.useState("");
  const [weddingDate, setWeddingDate] = React.useState("");
  const [marriageRegime, setMarriageRegime] = React.useState("");
  const [email, setEmail] = React.useState("");

  const getDateToDay = () => {
    const today = new Date();
    today.setDate(today.getDate());
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  const handleDateFormat = (date: string) => {
    if (!date) {
      return null;
    }
    const parsedDate = parse(date, "yyyy-MM-dd", new Date());
    const formattedDate = format(parsedDate, "yyyy-MM-dd'T'00:00:00'Z'");
    return formattedDate;
  };

  const handleDateFormatInput = (dateString: string) => {
    if (!dateString) return "";

    const inputFormat = "dd/MM/yyyy";

    const date = parse(dateString, inputFormat, new Date(), { locale: ptBR });

    const outputFormat = "yyyy-MM-dd";

    return format(date, outputFormat);
  };

  const handleGetProffesion = async () => {
    setLoadingProfession(true);

    try {
      const response = await oportunityServiceInstance.getProfission();

      if (response.status >= 400 && response?.status !== 401) {
        setLoadingProfession(false);
        setDataProfession([]);
        console.log("status 400", response);
        return;
      }
      if (response.status === 401) {
        setLoadingProfession(false);
        setIsUnauthorized(true);
        setDataProfession([]);
        return;
      }

      const dataArray: any = Object.values(response);

      setDataProfession(dataArray);
      setLoadingProfession(false);
    } catch (error) {
      setLoadingProfession(false);
      console.log("errorHandleGetProffesion", error);
    }
  };

  const renderedOptionsProfession = optionProfession(
    dataProfession,
    selectProfession,
    setSelectProfession,
    setProfession,
    setIdProfession
  );

  const handleUpdateOp = async () => {
    setLoading(true);
    try {
      const body = {
        opportunityIdSalesforce: accessCreateOp.oportunityIdSalesforce,
        OportunidadeId: accessCreateOp.oportunidadeId,
        NomeConta: name === undefined || name === "" ? null : name,
        accountIdSalesforce: accountIdSalesforce,
        dataNascimento: handleDateFormat(dateBirth),
        nacionalidade: nationality,
        cpf: cpf,
        rg: rg,
        dataExpedicaoRG: handleDateFormat(dateIssue),
        orgaoExpedidorRG: dispatcherOrg,
        profissao: profession,
        estadoCivil: civilStatus,
        dataCasamento: handleDateFormat(weddingDate),
        regimeCasamento: marriageRegime,
        emailPrincipal: email,
      };

      const response = await oportunityServiceInstance.updateOportunity(body);

      if (response.success === false) {
        setLoading(false);
        displayMessageError({ message: response.err });
        return;
      }

      setLoading(false);
      // displayMessage("Dados editados com sucesso!");

      onSuccess();
    } catch (error) {
      setLoading(false);
      console.log("ErrorHandleUpdateOp", error);
    }
  };

  const handleUpdateAccount = async () => {
    setLoading(true);
    try {
      const body = {
        AccountIdSalesforce: accountIdSalesforce,
        Nome: name,
        DataNascimento: handleDateFormat(dateBirth),
        Nacionalidade: nationality,
        CPFCNPJ: cpf,
        RG: rg,
        DataExpedicaoRG: handleDateFormat(dateIssue),
        OrgaoExpedidorRG: dispatcherOrg,
        Profissao: profession,
        EstadoCivil: civilStatus,
        DataCasamento: handleDateFormat(weddingDate),
        RegimeCasamento: marriageRegime,
        Email: email,
      };

      const response = await salesServiceInstance.updateAccount(body);

      if (response.status >= 400 && response?.status !== 401) {
        setLoading(false);
        const errorMessage =
          typeof response.data === "string"
            ? response.data
            : JSON.stringify(response.data);
        displayMessageError({ message: errorMessage });
        return;
      }

      setLoading(false);
      handleUpdateOp();
    } catch (error) {
      setLoading(false);
      console.log("ErrorHandleUpdateAccount", error);
    }
  };

  React.useEffect(() => {
    const isCnpjComplete = documentMask(cpf)?.length === 18;
    const validDocument =
      documentMask(cpf)?.length < 14 ||
      (documentMask(cpf)?.length > 14 && !isCnpjComplete);

    const marriedStatusNotFilled =
      civilStatus === "Casado(a)" &&
      (marriageRegime === null ||
        marriageRegime === "" ||
        weddingDate === null ||
        weddingDate === "");

    if (isRequired === "AC") {
      const isRequirement =
        validDocument ||
        !cpf ||
        !rg ||
        marriedStatusNotFilled ||
        (!isEmail(email) && !!email);

      setRequire(isRequirement);
    } else {
      const isRequirement =
        validDocument ||
        !name ||
        !cpf ||
        !rg ||
        !dispatcherOrg ||
        !dateIssue ||
        !dateBirth ||
        !nationality ||
        !profession ||
        marriedStatusNotFilled ||
        (!isEmail(email) && !!email);

      setRequire(isRequirement);
    }
  }, [
    civilStatus,
    marriageRegime,
    weddingDate,
    cpf,
    dateBirth,
    dateIssue,
    dispatcherOrg,
    isRequired,
    name,
    nationality,
    profession,
    rg,
    email,
  ]);

  React.useEffect(() => {
    const handleGetOportunityById = async () => {
      setLoadingGetOportunity(true);
      try {
        const response = await oportunityServiceInstance.getOportunityById({
          OportunidadeId: accessCreateOp.oportunidadeId,
        });

        if (response.status === 401) {
          setLoadingGetOportunity(false);
          setIsUnauthorized(true);
          return;
        }

        setName(response?.nomeDaConta);
        setCpf(response?.cpf);
        setRg(response?.rg);
        setDispatcherOrg(response?.orgaoExpedidorRG);
        setWeddingDate(handleDateFormatInput(response?.dataCasamento));
        setMarriageRegime(response?.regimeCasamento);
        setDateIssue(handleDateFormatInput(response?.dataExpedicaoRG));
        setDateBirth(handleDateFormatInput(response?.dataNascimento));
        setNationality(response?.nacionalidade);
        setProfession(
          response?.profissao === "" || response?.profissao === null
            ? ""
            : response?.profissao
        );
        setSelectProfession(
          response?.profissao === "" || response?.profissao === null
            ? ""
            : response?.profissao
        );
        setCivilStatus(response?.estadoCivil);
        setEmail(response?.emailPrincipal);
        setLoadingGetOportunity(false);
      } catch (error) {
        setLoadingGetOportunity(false);
        console.log("ErrorHandleGetOportunityById", error);
      }
    };

    handleGetOportunityById();
  }, [accessCreateOp.oportunidadeId]);
  return (
    <>
      <Alert
        onOpen={isUnauthorized}
        title="Erro ao carregar os dados!"
        titleButton={"Logar novamente"}
        description={
          "Sua sessão expirou, faça login novamente para ter acesso."
        }
        onClick={() => {
          signOut();
        }}
      />
      {loadingGetOportunity ? (
        <Styled.LoadingSpinner>
          <Spinner />
        </Styled.LoadingSpinner>
      ) : (
        <>
          <Styled.ContainerTaskCall>
            <Styled.ContainerInputsGrid>
              <InputText
                isRequirement={isRequired === "AC" ? false : true}
                label="Nome:"
                placeholder="Nome"
                name="Nome"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <InputText
                isRequirement
                label="CPF/CNPJ:"
                placeholder="CPF/CNPJ"
                name="CPF/CNPJ"
                value={cpf}
                onChange={(e) => setCpf(documentMask(e.target.value))}
                maxLength={18}
              />
              <InputText
                isRequirement
                label="RG:"
                placeholder="RG"
                name="RG"
                value={rg}
                onChange={(e) => setRg(rgMask(e.target.value))}
                maxLength={18}
              />
              <InputText
                isRequirement={isRequired === "AC" ? false : true}
                label="Org. Expedidor:"
                placeholder="Org. Expedidor"
                name="dispatcherOrg"
                value={dispatcherOrg}
                onChange={(e) => setDispatcherOrg(e.target.value)}
              />
              <InputDate
                type="date"
                isRequirement={isRequired === "AC" ? false : true}
                max={getDateToDay()}
                name={"dateIssue"}
                placeholder="__/__/___"
                label="Data de Emissão:"
                value={dateIssue}
                onChange={(e) => setDateIssue(e.target.value)}
              />
              <InputDate
                type="date"
                isRequirement={isRequired === "AC" ? false : true}
                max={getDateToDay()}
                name={"dateBirth"}
                placeholder="__/__/___"
                label="Data de nascimento:"
                value={dateBirth}
                onChange={(e) => setDateBirth(e.target.value)}
              />

              <InputText
                label="E-mail:"
                placeholder="E-mail"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                error={!isEmail(email) && !!email}
                messageError={"E-mail inválido"}
              />

              <InputSelect
                isRequirement={isRequired === "AC" ? false : true}
                widht="28%"
                title="Nacionalidade:"
                label={nationality || "Selecione uma opção"}
                isSelected={nationality}
                options={optionNationality}
                selectedOption={nationality}
                onChange={(e) => setNationality(e.currentTarget.value)}
              />

              <InputSelectSearch
                isRequirement={isRequired === "AC" ? false : true}
                width="30%"
                height="25%"
                title="Profissão:"
                placeholder="Pesquise por profissão"
                value={selectProfession}
                onChangeInput={(e) => setSelectProfession(e.target.value)}
                isSelected={profession}
                onChangeIcon={() => {
                  setSelectProfession("");
                  setProfession("");
                }}
                onClickSearchIcon={handleGetProffesion}
                loading={loadingProfession}
              >
                {renderedOptionsProfession}
              </InputSelectSearch>

              <InputSelect
                widht="28%"
                title="Estado Civil:"
                label={civilStatus || "Selecione uma opção"}
                isSelected={civilStatus}
                options={optionCivilStatus}
                selectedOption={civilStatus}
                onChange={(e) => setCivilStatus(e.currentTarget.value)}
              />

              {civilStatus === "Casado(a)" && (
                <>
                  <InputDate
                    type="date"
                    isRequirement
                    max={getDateToDay()}
                    name={"weddingDate"}
                    placeholder="__/__/___"
                    label="Data casamento:"
                    value={weddingDate}
                    onChange={(e) => setWeddingDate(e.target.value)}
                  />

                  <InputSelect
                    isRequirement
                    widht="30%"
                    title="Regime casamento:"
                    label={marriageRegime || "Selecione uma opção"}
                    isSelected={marriageRegime}
                    options={optionMarriageRegime}
                    selectedOption={marriageRegime}
                    onChange={(e) => setMarriageRegime(e.currentTarget.value)}
                  />
                </>
              )}
            </Styled.ContainerInputsGrid>
          </Styled.ContainerTaskCall>

          <Styled.DividerBoxInput />

          <Styled.ContainerFooterBox>
            <Styled.ContainerDuoMandatory>
              <span>(*) Campo de preenchimento obrigatório</span>
              <span>
                (<AiOutlineInfoCircle />) O campo apresenta formato ou dado
                inválido
              </span>
            </Styled.ContainerDuoMandatory>
            <Styled.ContainerButtonFooter>
              <Button error title="CANCELAR" onClick={onBack} />

              <Button
                title={
                  loading || loadindSuccess ? <Spinner /> : "SALVAR E ENVIAR"
                }
                onClick={handleUpdateAccount}
                disabled={loading || require || loadindSuccess}
              />
            </Styled.ContainerButtonFooter>
          </Styled.ContainerFooterBox>
        </>
      )}
    </>
  );
};

export default UpdateDataClientComponent;
