import React from "react";
import InputLocked from "@components/MyProfile/Inputs/InputLocked";
import {
  capitalize,
  documentMask,
  formatDatePtBr,
  phoneMask,
} from "@helpers/format";
import * as Styled from "../../styles";
import {
  ISection,
  ISectionDadosFamiliares,
  ISectionDadosFinanceiros,
  ISectionDadosPessoais,
} from "src/@types/MyProfile";
import InputEditable from "@components/MyProfile/Inputs/InputEditable";
import InputSelectEditable from "@components/MyProfile/Inputs/InputSelectEditable";
import { RadioButtonsList } from "../SelectOptionComponent";
import {
  optionBank,
  optionCivilStatus,
  optionNationality,
  optionSchooling,
  optionTshirt,
  optionTypeAccount,
  optionUF,
} from "@helpers/optionSelect";

export const SectionInformacoeesDeContato = ({ formik }: ISection) => {
  return (
      <Styled.ContainerInput>
        <Styled.SubContainerInput>
          <InputLocked
            label="Nome completo:"
            name="name"
            value={capitalize(formik.values.name)}
          />
        </Styled.SubContainerInput>
        <Styled.SubContainerInput>
          <InputLocked
            label="Regional:"
            name="regional"
            value={formik.values.regional}
          />
        </Styled.SubContainerInput>
        <Styled.SubContainerInput>
          <InputLocked label="Equipe:" name="team" value={formik.values.team} />
        </Styled.SubContainerInput>
        <Styled.SubContainerInput>
          <InputLocked label="Sexo:" name="sexo" value={formik.values.sexo} />
        </Styled.SubContainerInput>
      </Styled.ContainerInput>
  );
};

export const SectionDadosPessoais = ({
  formik,
  selectCivilStatus,
  selectTShirt,
  selectSchooling,
  selectNacionality,
  selectNaturalness,
  selectUfRg,
  setIsEditSelect,
  setSelectCivilStatus,
  setSelectNacionality,
  setSelectNaturalness,
  setSelectTshirt,
  setSelectSchooling,
  setSelectUfRg,
}: ISectionDadosPessoais) => {
  const handleRadioClick = (
    e: React.ChangeEvent<HTMLInputElement>,
    setValue: React.Dispatch<React.SetStateAction<string>>
  ): void => {
    setIsEditSelect(true);
    setValue(e.currentTarget.value);
  };

  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = formatDatePtBr(e.target.value);
    formik.handleChange(e);
    formik.setFieldValue("dateBt", newValue);
  };

  return (
    <>
      <Styled.ContainerInput>
        <Styled.SubContainerInput>
          <InputEditable
            label="Data de nascimento:"
            name="dateBt"
            value={formik.values.dateBt}
            onChange={handleDateChange}
            maxLength={10}
            error={formik.values.dateBt === ""}
          />
        </Styled.SubContainerInput>
        <Styled.SubContainerInput>
          <InputSelectEditable
            title="Estado civil:"
            label={selectCivilStatus || "Escolha uma opção"}
            isSelected={selectCivilStatus}
            error={selectCivilStatus === ""}
          >
            <RadioButtonsList
              options={optionCivilStatus}
              selectedOption={selectCivilStatus}
              onChange={(e) => handleRadioClick(e, setSelectCivilStatus)}
            />
          </InputSelectEditable>
        </Styled.SubContainerInput>
        <Styled.SubContainerInput>
          <InputLocked
            label="CPF:"
            name="cpf"
            value={documentMask(formik.values.cpf)}
          />
        </Styled.SubContainerInput>
        <Styled.SubContainerInput>
          <InputSelectEditable
            title="Camiseta:"
            label={
              selectTShirt ? `Tamanho (${selectTShirt})` : "Escolha um tamanho"
            }
            isSelected={selectTShirt}
            error={selectTShirt === ""}
          >
            <RadioButtonsList
              options={optionTshirt}
              selectedOption={selectTShirt}
              onChange={(e) => handleRadioClick(e, setSelectTshirt)}
            />
          </InputSelectEditable>
        </Styled.SubContainerInput>
        <Styled.SubContainerInput>
          <InputSelectEditable
            title="Escolaridade:"
            label={selectSchooling || "Não consta"}
            isSelected={selectSchooling}
            disabled={!selectSchooling}
          >
            <RadioButtonsList
              options={optionSchooling}
              selectedOption={selectSchooling}
              onChange={(e) => handleRadioClick(e, setSelectSchooling)}
            />
          </InputSelectEditable>
        </Styled.SubContainerInput>
      </Styled.ContainerInput>

      <Styled.ContainerInput>
        <Styled.SubContainerInput>
          <InputSelectEditable
            title="Nacionalidade:"
            label={selectNacionality || "Escolha uma opção"}
            width="15%"
            isSelected={selectNacionality}
            error={selectNacionality === ""}
          >
            <RadioButtonsList
              options={optionNationality}
              selectedOption={selectNacionality}
              onChange={(e) => handleRadioClick(e, setSelectNacionality)}
            />
          </InputSelectEditable>
        </Styled.SubContainerInput>
        <Styled.SubContainerInput>
          <InputEditable
            label="Naturalidade:"
            name="naturalness"
            value={formik.values.naturalness}
            onChange={formik.handleChange}
            error={formik.values.naturalness === ""}
          />
        </Styled.SubContainerInput>

        <Styled.SubContainerInput>
          <InputSelectEditable
            title="UF Naturalidade:"
            label={selectNaturalness || "Escolha uma opção"}
            width="15%"
            isSelected={selectNaturalness}
            error={selectNaturalness === ""}
          >
            <RadioButtonsList
              options={optionUF}
              selectedOption={selectNaturalness}
              onChange={(e) => handleRadioClick(e, setSelectNaturalness)}
            />
          </InputSelectEditable>
        </Styled.SubContainerInput>
        <Styled.SubContainerInput>
          <InputEditable
            label="PIS:"
            name="pis"
            value={formik?.values?.pis?.replace(/\D/g, "")}
            onChange={formik.handleChange}
            maxLength={11}
          />
        </Styled.SubContainerInput>
        <Styled.SubContainerInput>
          <InputSelectEditable
            title="UF RG:"
            label={selectUfRg || "Escolha uma opção"}
            isSelected={selectUfRg}
            width="15%"
            error={selectUfRg === ""}
          >
            <RadioButtonsList
              options={optionUF}
              selectedOption={selectUfRg}
              onChange={(e) => handleRadioClick(e, setSelectUfRg)}
            />
          </InputSelectEditable>
        </Styled.SubContainerInput>
        <Styled.SubContainerInput>
          <InputLocked label="RG:" name="rg" value={formik.values.rg} />
        </Styled.SubContainerInput>
      </Styled.ContainerInput>
    </>
  );
};

export const SectionDadosParaContato = ({ formik }: ISection) => {
  return (
    <>
      <Styled.ContainerInput>
        <Styled.SubContainerInput>
          <InputEditable
            label="Telefone:"
            name="telephone"
            value={formik?.values?.telephone?.replace(/\D/g, "")}
            onChange={formik.handleChange}
            maxLength={11}
          />
        </Styled.SubContainerInput>
        <Styled.SubContainerInput>
          <InputEditable
            label="Celular:"
            name="cell"
            value={phoneMask(formik.values.cell)}
            onChange={formik.handleChange}
            error={formik.values.cell === ""}
          />
        </Styled.SubContainerInput>
        <Styled.SubContainerInput>
          <InputEditable
            label="Celular 2:"
            name="cell2"
            value={phoneMask(formik.values.cell2)}
            onChange={formik.handleChange}
          />
        </Styled.SubContainerInput>
      </Styled.ContainerInput>

      <Styled.ContainerInput>
        <Styled.SubContainerInput>
          <InputLocked
            label="E-mail:"
            name="email"
            value={formik.values.email}
          />
        </Styled.SubContainerInput>
        <Styled.SubContainerInput>
          <InputLocked
            label="E-mail (Direcional):"
            name="emailDirecional"
            value={formik.values.emailDirecional}
          />
        </Styled.SubContainerInput>
        <Styled.SubContainerInput>
          <InputEditable
            label="Outro telefone:"
            name="otherTell"
            value={formik?.values?.otherTell?.replace(/\D/g, "")}
            onChange={formik.handleChange}
            maxLength={11}
          />
        </Styled.SubContainerInput>
      </Styled.ContainerInput>
    </>
  );
};

export const SectionDadosFamiliares = ({
  formik,
  selectCivilStatus,
}: ISectionDadosFamiliares) => {
  return (
    <>
      <Styled.ContainerInput>
        <Styled.SubContainerInput>
          <InputEditable
            label="Nome do pai:"
            name="fatherName"
            value={formik.values.fatherName}
            onChange={formik.handleChange}
            error={formik.values.fatherName === ""}
          />
        </Styled.SubContainerInput>
        <Styled.SubContainerInput>
          <InputEditable
            label="Nome do Cônjuge:"
            name="spouse"
            value={formik.values.spouse}
            onChange={formik.handleChange}
            error={
              selectCivilStatus === "Casado" && formik.values.spouse === ""
            }
          />
        </Styled.SubContainerInput>
      </Styled.ContainerInput>

      <Styled.ContainerInput>
        <Styled.SubContainerInput>
          <InputEditable
            label="Nome da mãe:"
            name="motherName"
            value={formik.values.motherName}
            onChange={formik.handleChange}
            error={formik.values.motherName === ""}
          />
        </Styled.SubContainerInput>
        <Styled.SubContainerInput>
          <InputEditable
            label="Quantidade de filhos:"
            name="quantitySoon"
            value={formik.values.quantitySoon}
            onChange={formik.handleChange}
          />
        </Styled.SubContainerInput>
      </Styled.ContainerInput>
    </>
  );
};

export const SectionCRECITTI = ({ formik }: ISection) => {
  return (
    <>
      <Styled.ContainerInput>
        <Styled.SubContainerInput>
          <InputLocked
            label="Data Matrícula - TTI:"
            name="dtRegistrationTti"
            value={formik.values.dtRegistrationTti}
          />
        </Styled.SubContainerInput>
        <Styled.SubContainerInput>
          <InputLocked
            label="CRECI:"
            name="creci"
            value={formik.values.creci}
          />
        </Styled.SubContainerInput>
        <Styled.SubContainerInput>
          <InputLocked
            label="Validade CRECI"
            name="validCreci"
            value={formik.values.validCreci}
          />
        </Styled.SubContainerInput>
        <Styled.SubContainerInput>
          <InputLocked
            label="Responsável:"
            name="responsible"
            value={formik.values.responsible}
          />
        </Styled.SubContainerInput>
      </Styled.ContainerInput>

      <Styled.ContainerInput>
        <Styled.SubContainerInput>
          <InputLocked
            label="Data conclusão:"
            name="dtConclusion"
            value={formik.values.dtConclusion}
          />
        </Styled.SubContainerInput>
        <Styled.SubContainerInput>
          <InputLocked
            label="Status CRECI:"
            name="statusCreci"
            value={formik.values.statusCreci}
          />
        </Styled.SubContainerInput>
        <Styled.SubContainerInput>
          <InputLocked
            label="Observações:"
            name="obs"
            value={formik.values.obs}
          />
        </Styled.SubContainerInput>
        <Styled.SubContainerInput>
          <InputLocked
            label="CRECI do responsável:"
            name="responsibleCreci"
            value={formik.values.responsibleCreci}
          />
        </Styled.SubContainerInput>
      </Styled.ContainerInput>
    </>
  );
};

export const SectionDadosFinanceiros = ({
  formik,
  selectBank,
  setSelectBank,
  selectTypeAccount,
  setSelectTypeAccount,
  setIsEditSelect,
}: ISectionDadosFinanceiros) => {
  const handleRadioClick = (
    e: React.ChangeEvent<HTMLInputElement>,
    setValue: React.Dispatch<React.SetStateAction<string>>
  ): void => {
    setIsEditSelect(true);
    setValue(e.currentTarget.value);
  };

  return (
    <Styled.ContainerInput>
      <Styled.SubContainerInput>
        <InputSelectEditable
          title="Banco:"
          label={selectBank || "Escolha uma opção"}
          isSelected={selectBank}
          error={selectBank === ""}
        >
          <RadioButtonsList
            options={optionBank}
            selectedOption={selectBank}
            onChange={(e) => handleRadioClick(e, setSelectBank)}
          />
        </InputSelectEditable>
      </Styled.SubContainerInput>
      <Styled.SubContainerInput>
        <InputEditable
          label="Agência:"
          name="agency"
          value={formik.values.agency}
          onChange={formik.handleChange}
          error={formik.values.agency === ""}
        />
      </Styled.SubContainerInput>
      <Styled.SubContainerInput>
        <InputSelectEditable
          title="Tipo de Conta:"
          label={selectTypeAccount || "Escolha uma opção"}
          isSelected={selectTypeAccount}
        >
          <RadioButtonsList
            options={optionTypeAccount}
            selectedOption={selectTypeAccount}
            onChange={(e) => handleRadioClick(e, setSelectTypeAccount)}
          />
        </InputSelectEditable>
      </Styled.SubContainerInput>
      <Styled.SubContainerInput>
        <InputEditable
          label="Conta:"
          name="account"
          value={formik.values.account}
          onChange={formik.handleChange}
          error={formik.values.account === ""}
        />
      </Styled.SubContainerInput>
    </Styled.ContainerInput>
  );
};

export const SectionTipoDeComissionamento = ({ formik }: ISection) => {
  return (
    <Styled.ContainerInput>
      <Styled.SubContainerInput>
        <InputLocked
          label="Tipo Corretor:"
          name="typeCorretor"
          value={formik.values.typeCorretor}
        />
      </Styled.SubContainerInput>
      <Styled.SubContainerInput>
        <InputLocked
          label="Faturamento Comissão:"
          name="commissionBilling"
          value={formik.values.commissionBilling}
        />
      </Styled.SubContainerInput>
      <Styled.SubContainerInput>
        <InputEditable
          label="CNPJ:"
          name="cnpj"
          value={documentMask(formik.values.cnpj)}
          onChange={formik.handleChange}
          maxLength={18}
        />
      </Styled.SubContainerInput>
    </Styled.ContainerInput>
  );
};

export const SectionEnderecos = ({ formik }: ISection) => {
  return (
    <>
      <Styled.ContainerInput>
        <Styled.SubContainerInput>
          <InputLocked
            label="Número:"
            name="number"
            value={formik.values.number}
          />
        </Styled.SubContainerInput>
        <Styled.SubContainerInput>
          <InputLocked
            label="Bairro:"
            name="neighborhood"
            value={formik.values.neighborhood}
          />
        </Styled.SubContainerInput>
        <Styled.SubContainerInput>
          <InputLocked
            label="Logradouro:"
            name="logradouro"
            value={formik.values.logradouro}
          />
        </Styled.SubContainerInput>
        <Styled.SubContainerInput>
          <InputLocked
            label="Estado:"
            name="state"
            value={formik.values.state}
          />
        </Styled.SubContainerInput>
      </Styled.ContainerInput>

      <Styled.ContainerInput>
        <Styled.SubContainerInput>
          <InputLocked
            label="Complemento:"
            name="complement"
            value={formik.values.complement}
          />
        </Styled.SubContainerInput>
        <Styled.SubContainerInput>
          <InputLocked label="Cidade:" name="city" value={formik.values.city} />
        </Styled.SubContainerInput>
        <Styled.SubContainerInput>
          <InputLocked
            label="CEP:"
            name="cep"
            value={formik.values?.cep?.replace(/(\d{5})(\d)/, "$1-$2")}
          />
        </Styled.SubContainerInput>
      </Styled.ContainerInput>
    </>
  );
};
