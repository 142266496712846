import React from "react";

const checkEmptyFields = (formik: any, fields: string[]): string[] => {
  const emptyFields: string[] = [];
  fields.forEach((field) => {
    if (formik.getFieldProps(field).value === "") {
      emptyFields.push(field);
    }
  });
  return emptyFields;
};

type CustomFormikEmptyProps = {
  formik: any;
  tShirt: string;
  civilStatus: string;
  nacionality: string;
  UfNaturalness: string;
  UfRg: string;
  bank: string;
  TipoCorretor: string;
  CommissionBilling: string;
  State: string;
};

export const useCustomFormikEmpty = ({
  formik,
  tShirt,
  civilStatus,
  nacionality,
  UfNaturalness,
  UfRg,
  bank,
  TipoCorretor,
  CommissionBilling,
  State,
}: CustomFormikEmptyProps) => {
  const [empty, setEmpty] = React.useState<string[]>([]);
  const [errorEmpty, setErrorEmpty] = React.useState(false);

  React.useEffect(() => {
    const fieldsToCheck: string[] = [
      "cell",
      "dateBt",
      "naturalness",
      "fatherName",
      "motherName",
      "agency",
      "account",
      "digiteAccount",
      "logradouro",
      "number",
      "neighborhood",
      "city",
      "cep",
    ];

    const emptyFields = checkEmptyFields(formik, fieldsToCheck);

    if (civilStatus === "Casado") {
      fieldsToCheck.push("spouse");
    }

    fieldsToCheck.push(
      "tShirt",
      "civilStatus",
      "nacionality",
      "UfNaturalness",
      "UfRg",
      "bank",
      "TipoCorretor",
      "CommissionBilling",
      "State"
    );

    const newUpdates = checkEmptyFields(formik, fieldsToCheck);

    if (emptyFields.length > 0 || newUpdates.length > 0) {
      setEmpty([...emptyFields, ...newUpdates]);
      setErrorEmpty(false);
    } else {
      setEmpty([]);
      setErrorEmpty(true);
    }
  }, [
    CommissionBilling,
    State,
    TipoCorretor,
    UfNaturalness,
    UfRg,
    bank,
    civilStatus,
    formik.getFieldProps,
    nacionality,
    tShirt,
  ]);

  return { empty, errorEmpty };
};
