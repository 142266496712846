import React from "react";
import * as Styled from "../stylesView&Create";
import oportunityService from "@services/Oportunity/OportunityService";
import { OportunityContext } from "@context/oportunityContex";
import Spinner from "@components/common/Spinner";
import { FiPlus, FiUsers } from "react-icons/fi";
import Button from "@components/common/Button";
import Modal from "@components/Sales/Modal";
import ViewRalationshipComponent from "../Relationship/ViewRalationshipComponent";
import CreateRalationshipComponent from "../Relationship/CreateRalationshipComponent";
import Alert from "@components/common/Alert";
import { LoginContext } from "@context/loginContext";
import { IHashMapKeyString } from "src/@types/Sales";
import { FaRegTrashAlt } from "react-icons/fa";
import {
  useMessageDeleteToast,
  useOnlyMessageToast,
} from "@hook/useMessageToast";
import { useMessageErrorToast } from "@hook/useMessageErrorToast";

interface IProp {
  indexFirstTab: number;
  navigationCreateAccount: () => void;
  updateData: { data: string };
}

const RelationshipComponent = ({
  indexFirstTab,
  navigationCreateAccount,
  updateData,
}: IProp) => {
  const displayMessageDelete = useMessageDeleteToast();
  const displayMessageSuccess = useOnlyMessageToast();
  const displayMessageError = useMessageErrorToast();
  const { signOut } = React.useContext(LoginContext);
  const {
    faseOp,
    accessCreditRating,
    setAccessRelationship,
    accessRelationship,
    opportunityIdSalesforce,
    accessCreateOp,
    idRelacionamentoComprador,
    setIdRelacionamentoComprador,
    setLoadingDeleteRelationship,
  } = React.useContext(OportunityContext);
  const [hash, setHash] = React.useState("Relationship");
  const [data, setData] = React.useState([]);

  const [account, setAccount] = React.useState("");
  const [accountIdSalesforce, setAccountIdSalesforce] = React.useState("");

  const [successEditRelationship, setSuccessEditRelationship] =
    React.useState(false);
  const [edit, setEdit] = React.useState(false);

  const [isUnauthorized, setIsUnauthorized] = React.useState(false);
  const [loadingRealtionship, setLoadingRealtionship] = React.useState(false);
  const [compradorPrincipal, setCompradorPrincipal] = React.useState(false);
  const [mandatoryCreateAccount, setMandatoryCreateAccount] =
    React.useState(false);

  const [showCreateRelationship, setShowCreateRelationship] =
    React.useState(false);
  const [successCreateRalationship, setSuccessCreateRalationship] =
    React.useState(false);

  const filteredRows = React.useMemo(() => {
    if (!data) return [];
    return data;
  }, [data]);

  const handleTypeRelationshipReverse = (typeRelationship: number) => {
    if (typeRelationship === 1) return "Compositor de Renda";
    if (typeRelationship === 2) return "Cônjuge";
    if (typeRelationship === 3) return "Representante Legal";
    if (typeRelationship === 4) return "Fiador";
    if (typeRelationship === 5) return "";
    return "";
  };

  const handleGetRealtionship = async () => {
    setLoadingRealtionship(true);
    try {
      const response = await oportunityService.getRealtionship({
        OpportunityIdSalesforce:
          opportunityIdSalesforce || accessCreateOp.oportunityIdSalesforce,
      });

      if (response.status >= 400 && response?.status !== 401) {
        setLoadingRealtionship(false);
        setData([]);
        console.log("status 400", response);
        return;
      }

      if (response.status === 401) {
        setLoadingRealtionship(false);
        setIsUnauthorized(true);
        return;
      }

      setData(response);
      setLoadingRealtionship(false);
    } catch (error) {
      setLoadingRealtionship(false);
      console.log("ErrorHandleGetRealtionship", error);
    }
  };

  const handleDeleteRealtionship = async (
    idRelacionamentoComprador: string
  ) => {
    setLoadingRealtionship(true);
    setLoadingDeleteRelationship(true);
    try {
      const response = await oportunityService.deleteRealtionship({
        IdRelacionamentoComprador: idRelacionamentoComprador,
      });

      if (response.status >= 400 && response?.status !== 401) {
        setLoadingRealtionship(false);
        setLoadingDeleteRelationship(false);
        console.log("status 400", response);
        return;
      }

      if (response.status === 401) {
        setLoadingRealtionship(false);
        setLoadingDeleteRelationship(false);
        setIsUnauthorized(true);
        return;
      }

      handleGetRealtionship();
      displayMessageSuccess("Relacionamento deletado com sucesso!");
      setLoadingRealtionship(false);
      setLoadingDeleteRelationship(false);
    } catch (error) {
      setLoadingRealtionship(false);
      setLoadingDeleteRelationship(false);
      console.log("ErrorHandleDeleteRealtionship", error);
    }
  };

  const RelationshipHashMap: IHashMapKeyString = {
    Relationship: (
      <>
        <Styled.ContainerHeaderWithButton>
          <Styled.TitleCreateRelationship>
            <h1>Relacionamentos com o comprador</h1>
          </Styled.TitleCreateRelationship>

          <Styled.ContaineButtonsHeader>
            <Button
              title={
                <Styled.TitleButon>
                  <FiPlus />
                  NOVO RELACIONAMENTO
                </Styled.TitleButon>
              }
              onClick={() => setShowCreateRelationship(true)}
              disabled={faseOp}
            />
          </Styled.ContaineButtonsHeader>
        </Styled.ContainerHeaderWithButton>

        <Styled.ContainerActivityCreditRating>
          <Styled.ContainerRelationship>
            {loadingRealtionship ? (
              <Styled.ContainerRelationshipLoading>
                <Spinner />
              </Styled.ContainerRelationshipLoading>
            ) : (
              <>
                <Styled.ContainerTitleLabelRelationship>
                  {filteredRows?.length === 0 ? (
                    <Styled.ContainerRelationshipEmpty>
                      <span>
                        Não há relacionamento com o comprador nesta
                        oportunidade.
                      </span>
                    </Styled.ContainerRelationshipEmpty>
                  ) : (
                    <>
                      <Styled.TitleLabelRelationshipLeft>
                        ID do relacionamento com o comprador:
                      </Styled.TitleLabelRelationshipLeft>

                      <Styled.ContainerTitleLabelRelationshipRight>
                        <Styled.TitleLabelRelationshipRight>
                          CPF:
                        </Styled.TitleLabelRelationshipRight>
                        <Styled.TitleLabelRelationshipRight>
                          Tipo:
                        </Styled.TitleLabelRelationshipRight>
                        <Styled.TitleLabelRelationshipRight>
                          Percentual:
                        </Styled.TitleLabelRelationshipRight>
                      </Styled.ContainerTitleLabelRelationshipRight>
                    </>
                  )}
                </Styled.ContainerTitleLabelRelationship>

                <Styled.ContainerTodoRelationship>
                  {filteredRows?.map(
                    ({
                      idRelacionamentoComprador,
                      accountIdSalesforce,
                      nome,
                      cpf,
                      codigoRelacionamentoComComprador,
                      percentualDoTitular,
                      tipoRelacionamento,
                      compradorPrincipal,
                    }: any) => (
                      <Styled.ContainerBoxRelationship
                        key={idRelacionamentoComprador}
                      >
                        <Styled.ContainerIconTextHistoric>
                          <Styled.ContainerIconHistoric>
                            <FiUsers />
                          </Styled.ContainerIconHistoric>
                          <Styled.ContainerTextHistoric>
                            <Styled.TitleTextHistoric>
                              {codigoRelacionamentoComComprador}
                            </Styled.TitleTextHistoric>
                            <Styled.SubTitleTextHistoric>
                              Conta: {nome?.toUpperCase()}
                            </Styled.SubTitleTextHistoric>
                          </Styled.ContainerTextHistoric>
                        </Styled.ContainerIconTextHistoric>

                        <Styled.ContainerDataStatus>
                          <Styled.ContainerIconTextDataStatus>
                            <Styled.ContainerTexDataStatus>
                              <Styled.ContainerBoxTexDataStatus>
                                <Styled.TitleTexDataStatus>
                                  {cpf}
                                </Styled.TitleTexDataStatus>
                              </Styled.ContainerBoxTexDataStatus>

                              <Styled.BoxDivider>
                                <Styled.DividerActivity />
                              </Styled.BoxDivider>

                              <Styled.ContainerBoxTexDataStatus>
                                <Styled.TitleTexDataStatus>
                                  {!handleTypeRelationshipReverse(
                                    tipoRelacionamento
                                  )
                                    ? "Comprador principal"
                                    : handleTypeRelationshipReverse(
                                        tipoRelacionamento
                                      )}
                                </Styled.TitleTexDataStatus>
                              </Styled.ContainerBoxTexDataStatus>

                              <Styled.BoxDivider>
                                <Styled.DividerActivity />
                              </Styled.BoxDivider>

                              <Styled.ContainerBoxTexDataStatus>
                                <Styled.TitleTexDataStatus>
                                  {percentualDoTitular}%
                                </Styled.TitleTexDataStatus>
                              </Styled.ContainerBoxTexDataStatus>

                              <Styled.BoxDivider>
                                <Styled.DividerActivity />
                              </Styled.BoxDivider>

                              <Styled.ContainerButtonDataStatus>
                                <Styled.ContainerButtonIconDataStatus>
                                  <Styled.AreaButtonIconDelete
                                    faseOp={compradorPrincipal}
                                    onClick={() => {
                                      if (compradorPrincipal) return;
                                      else {
                                        displayMessageDelete({
                                          title: `Deseja excluir o ${codigoRelacionamentoComComprador}?`,
                                          message:
                                            "Esse processo é irreversível, após a exclusão o relacionamento será desvinculado dessa oportunidade.",
                                          onClick: () =>
                                            handleDeleteRealtionship(
                                              idRelacionamentoComprador
                                            ),
                                        });
                                      }
                                    }}
                                  >
                                    <FaRegTrashAlt />
                                  </Styled.AreaButtonIconDelete>
                                </Styled.ContainerButtonIconDataStatus>
                                <Button
                                  title="VISUALIZAR"
                                  onClick={() => {
                                    setIdRelacionamentoComprador(
                                      idRelacionamentoComprador
                                    );
                                    setAccount(nome);
                                    setAccountIdSalesforce(accountIdSalesforce);
                                    setAccessRelationship({
                                      codigoRelacionamentoComComprador,
                                      nomeConta: nome,
                                    });
                                    setHash("ViewRelationship");
                                    setCompradorPrincipal(compradorPrincipal);
                                  }}
                                />
                              </Styled.ContainerButtonDataStatus>
                            </Styled.ContainerTexDataStatus>
                          </Styled.ContainerIconTextDataStatus>
                        </Styled.ContainerDataStatus>
                      </Styled.ContainerBoxRelationship>
                    )
                  )}
                </Styled.ContainerTodoRelationship>
              </>
            )}
          </Styled.ContainerRelationship>
        </Styled.ContainerActivityCreditRating>
      </>
    ),

    ViewRelationship: (
      <ViewRalationshipComponent
        onBack={() => setHash("Relationship")}
        title={`${accessRelationship.nomeConta} (${accessRelationship.codigoRelacionamentoComComprador})`}
        idRelacionamentoComprador={idRelacionamentoComprador}
        account={account}
        isEdit={edit}
        setIsEdit={setEdit}
        setSuccessEditRelationship={setSuccessEditRelationship}
        compradorPrincipal={compradorPrincipal}
        accountIdSalesforce={accountIdSalesforce}
        setAccountIdSalesforce={setAccountIdSalesforce}
      />
    ),
  };

  React.useEffect(() => {
    if (
      indexFirstTab === 4 ||
      successCreateRalationship ||
      successEditRelationship
    ) {
      handleGetRealtionship();
      // handleGetCreditRatingById();
    }
  }, [
    indexFirstTab,
    successCreateRalationship,
    successEditRelationship,
    accessCreditRating,
    updateData,
  ]);

  return (
    <>
      <Alert
        onOpen={isUnauthorized}
        title="Erro ao carregar os dados!"
        titleButton={"Logar novamente"}
        description={
          "Sua sessão expirou, faça login novamente para ter acesso."
        }
        onClick={() => {
          signOut();
        }}
      />

      <Modal
        onOpen={showCreateRelationship}
        title="Criar relacionamento com comprador"
        subtitle="Preencha as informações que serão enviadas para o correspondente bancário realizar a análise de crédito."
        onClose={() => {
          if (mandatoryCreateAccount) {
            displayMessageError({
              message: "Necessário criar relacionamento com comprador!",
            });
            return;
          } else {
            setShowCreateRelationship(false);
          }
        }}
      >
        <CreateRalationshipComponent
          accountName={account}
          idRelacionamentoComprador={idRelacionamentoComprador}
          setSuccessCreateRalationship={setSuccessCreateRalationship}
          idAvaliacaoCreditoSalesforce={
            accessCreditRating.idAvaliacaoCreditoSalesforce
          }
          onBack={() => setShowCreateRelationship(false)}
          setIdSuccessCreateRalationship={setIdRelacionamentoComprador}
          navigationCreateAccount={navigationCreateAccount}
          setMandatoryCreateAccount={setMandatoryCreateAccount}
          mandatoryCreateAccount={mandatoryCreateAccount}
          // navigationCreateAccount={() => {}}
        />
      </Modal>

      {RelationshipHashMap[hash]}
    </>
  );
};

export default RelationshipComponent;
